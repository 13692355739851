import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import {
  Cart, CartItem, CartNotification, ItemDisagreement, PaymentType, ShippingMethod, Store,
} from 'typing/models';
import { AbstractPayload, BaseState, CartState } from 'typing/store';

import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_TO_CART,
  LOAD_CART, SYNC_CART,
  VERIFY_CART,
  LOAD_PAYMENT_METHODS,
  LOAD_SHIPPING_METHODS,
  BUY_CART,
  GET_CART_NOTIFICATIONS,
  RESPONSE_CREDIT_CARD,
  LOAD_SELF_SHIPPING_METHODS,
  DOWNLOAD_CART,
} from 'utils/fetchs';

import { fetchStateGenerator, fetchStateWithMetaGenerator } from './checkFetchReducerCreator';

export const {
  syncCartRequest,
  syncCartSuccess,
  syncCartFailure,
  syncCartClean,
  downloadCartRequest,
  downloadCartSuccess,
  downloadCartFailure,
  downloadCartClean,
  addProductToCartRequest,
  addProductToCartSuccess,
  addProductToCartFailure,
  addProductToCartClean,
  removeProductToCartRequest,
  removeProductToCartSuccess,
  removeProductToCartFailure,
  removeProductToCartClean,
  loadCartRequest,
  loadCartSuccess,
  loadCartFailure,
  loadCartClean,
  verifyCartRequest,
  verifyCartSuccess,
  responseCreditCard,
  clearResponseCreditCard,
  responseMessage,
  verifyCartFailure,
  verifyCartClean,
  loadPaymentMethodsRequest,
  loadPaymentMethodsSuccess,
  loadPaymentMethodsFailure,
  loadShippingMethodsRequest,
  loadShippingMethodsSuccess,
  loadShippingMethodsFailure,
  loadPaymentMethodsClean,
  loadSelfShippingMethodsRequest,
  loadSelfShippingMethodsSuccess,
  loadSelfShippingMethodsFailure,
  loadSelfPaymentMethodsClean,
  buyCartRequest,
  buyCartSuccess,
  buyCartFailure,
  buyCartClean,
  getCartNotificationsRequest,
  getCartNotificationsSuccess,
  getCartNotificationsFailure,
  getCartNotificationsClean,
  debouncedSyncCart,
  setAddress,
  setPaymentMethod,
  setShippingMethod,
  setChangeValue,
  setVerifiedCart,
  setUnverifiedCart,
  startCart,
  clearCart,
  clearCartItems,
  setCheckoutStatus,
} = createActions(
  fetchStateWithMetaGenerator(SYNC_CART),
  ...fetchStateGenerator(DOWNLOAD_CART),
  ...fetchStateGenerator(ADD_PRODUCT_TO_CART),
  ...fetchStateGenerator(REMOVE_PRODUCT_TO_CART),
  ...fetchStateGenerator(LOAD_CART),
  ...fetchStateGenerator(VERIFY_CART),
  ...fetchStateGenerator(LOAD_PAYMENT_METHODS),
  ...fetchStateGenerator(LOAD_SHIPPING_METHODS),
  ...fetchStateGenerator(LOAD_SELF_SHIPPING_METHODS),
  ...fetchStateGenerator(BUY_CART),
  ...fetchStateGenerator(GET_CART_NOTIFICATIONS),
  'DEBOUNCED_SYNC_CART',
  'SET_ADDRESS',
  'SET_PAYMENT_METHOD',
  'SET_SHIPPING_METHOD',
  'SET_CHANGE_VALUE',
  'SET_VERIFIED_CART',
  'RESPONSE_CREDIT_CARD',
  'CLEAR_RESPONSE_CREDIT_CARD',
  'RESPONSE_MESSAGE',
  'SET_UNVERIFIED_CART',
  'START_CART',
  'CLEAR_CART',
  'CLEAR_CART_ITEMS',
  'SET_CHECKOUT_STATUS',
  { prefix: 'CART' },
);

const INITIAL_STATE = {
  data: {
    store: null,
    items: [],
    disagreements: [],
    payment_info: null,
    response_credit_cards: null,
    response_message: null,
    delivery_fee: 0,
    service_fee: 0,
    change_for_payment: null,
    change_for_shipping: null,
    shipping_method: null,
  },
  checkoutStatus: null,
  lastUpdate: null,
  paymentMethods: [],
  shippingMethods: [],
  selfShippingMethods: [],
  verifiedCart: false,
  notifications: [],
};

export const getCurrentShippingMethod = (state: BaseState):
    ShippingMethod | null | undefined => state.cart.data.shipping_method;

export const getShippingMethods = (state: BaseState):
    ShippingMethod[] => state.cart.shippingMethods;

export const getSelfShippingMethods = (state: BaseState):
    ShippingMethod[] => state.cart.selfShippingMethods;

export const getChangeForPayment = (state: BaseState):
  number | null => state.cart.data.change_for_payment;

export const getChangeForShipping = (state: BaseState):
    number | null => state.cart.data.change_for_shipping;

export const getCurrentPaymentMethod = (state: BaseState):
  PaymentType | null | undefined => state.cart.data.payment_type;

export const getPaymentMethods = (state: BaseState):
  PaymentType[] => state.cart.paymentMethods;

export const getDisagreements = (state: BaseState):
  ItemDisagreement[] => state.cart.data.disagreements;

export const productIsAddedInCart = (productId: number | string) => (state: BaseState):
  boolean => !!state.cart.data.items.find(
  (item) => item.product.product_child.id === productId,
);

export const getItemByProductId = (productId: number) => (state: BaseState):
  CartItem | undefined => state.cart.data.items.find(
  (item) => item.product.id === productId,
);

export const getItemNotifications = (productChildId: number | string) => (state: BaseState):
  CartNotification | undefined => state.cart.notifications.find(
  (notification) => !!notification[productChildId.toString()],
);

export const getCartNotifications = (state: BaseState):
  CartNotification[] => state.cart.notifications;

export const hasCartNotifications = (state: BaseState):
  boolean => !!state.cart.notifications.length;

export const getAddedProductInCart = (productId: number | string | undefined) => (state: BaseState):
  CartItem | undefined => state.cart.data.items.find(
  (item) => item.product.product_child.id.toString() === productId?.toString(),
);

export const getCartState = (state: BaseState): CartState => state.cart;
export const getCart = (state: BaseState): Cart => state.cart.data;
export const getCheckoutStatus = (state: BaseState): string | null => state.cart.checkoutStatus;
export const getCartStore = (state: BaseState): Store | null => state.cart.data.store;
export const getCartTotal = (state: BaseState): number => state.cart.data.items.reduce(
  (total,
    { quantity, product }) => {
    const unitPrice = product.product_child.promo_price ?? product.product_child.price;
    return total + quantity * unitPrice;
  },
  0,
) + state.cart.data.delivery_fee + state.cart.data.service_fee;

export const getCartSubTotal = (state: BaseState): number => state.cart.data.items.reduce(
  (total,
    { quantity, product }) => {
    const unitPrice = product.product_child.promo_price ?? product.product_child.price;
    return total + quantity * unitPrice;
  },
  0,
);

const cleanCart = produce((draft: CartState) => {
  draft.data = INITIAL_STATE.data;
  draft.checkoutStatus = null;
  draft.verifiedCart = false;
  draft.paymentMethods = [];
  draft.shippingMethods = [];
  draft.selfShippingMethods = [];
});

const cleanCartItems = produce((draft: CartState) => {
  draft.data.items = [];
});

export default handleActions<CartState, AbstractPayload>({
  [addProductToCartSuccess.toString()]: produce((draft: CartState, { payload }) => {
    const hasItemInCart = !!draft.data.items.find(
      (item) => item.product.product_child.id === payload.product.product_child.id,
    );

    if (hasItemInCart) {
      draft.data.items = draft.data.items.map((item) => {
        if (item.product.product_child.id === payload.product.product_child.id) {
          return payload;
        }
        return item;
      });
    } else {
      draft.data.items = [...draft.data.items, payload];
    }

    draft.lastUpdate = new Date();
  }),
  [removeProductToCartSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.items = draft.data.items.filter(
      (item) => item.product.product_child.id !== payload.product_child.id,
    );

    draft.lastUpdate = new Date();
  }),
  [loadPaymentMethodsSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.paymentMethods = payload;
  }),
  [loadShippingMethodsSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.shippingMethods = payload;
  }),
  [loadSelfShippingMethodsSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.selfShippingMethods = payload;
  }),
  [getCartNotificationsSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.notifications = payload;
  }),
  [loadCartSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.data = payload.data;
    draft.lastUpdate = payload.lastUpdate;
  }),
  [startCart.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.store = payload;
  }),
  [syncCartSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.id = payload.id;
    draft.data.state = payload.state;
    draft.data.client = payload.client;
    draft.data.delivery_fee = payload.delivery_fee;
    draft.data.service_fee = payload.service_fee;
    draft.data.shipping_method = payload.shipping_method;
    draft.data.items = payload.items.map(
      (item: any):
        any => ({
        product: {
          ...item.product,
          has_variants: true,
        },
        quantity: parseFloat(item.quantity),
        location_code: item.location_code,
      }),
    );
    // draft.data.items = draft.data.items.filter((cartItem) => !!payload.cart_items.find(
    //   (item) => item.product_id === cartItem.product.product_child.id,
    // ));
  }),
  [downloadCartSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.id = payload.id;
    draft.data.state = payload.state;
    draft.data.client = payload.client;
    draft.data.delivery_fee = payload.delivery_fee;
    draft.data.service_fee = payload.service_fee;
    draft.data.shipping_method = payload.shipping_method;
    draft.data.items = payload.items.map(
      (item: any):
      any => ({
        product: {
          ...item.product,
          has_variants: true,
        },
        quantity: parseFloat(item.quantity),
        location_code: item.location_code,
      }),
    );
    // draft.data.items = draft.data.items.filter((cartItem) => !!payload.cart_items.find(
    //   (item) => item.product_id === cartItem.product.product_child.id,
    // ));
  }),
  [buyCartSuccess.toString()]: cleanCart,
  [clearCart.toString()]: cleanCart,
  [clearCartItems.toString()]: cleanCartItems,
  [setCheckoutStatus.toString()]: produce((draft: CartState, { payload }) => {
    draft.checkoutStatus = payload;
  }),
  [setAddress.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.delivery_address = payload;
  }),
  [setPaymentMethod.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.payment_type = payload;
  }),
  [setShippingMethod.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.shipping_method = payload;
  }),
  [setVerifiedCart.toString()]: produce((draft: CartState, { payload }) => {
    draft.verifiedCart = draft.data.id === payload;
  }),
  [setUnverifiedCart.toString()]: produce((draft: CartState, { payload }) => {
    if (draft.data.id === payload) {
      draft.verifiedCart = false;
    }
  }),
  [responseCreditCard.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.response_credit_cards = payload;
  }),
  [clearResponseCreditCard.toString()]: produce((draft: CartState) => {
    draft.data.response_credit_cards = null;
  }),
  [responseMessage.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.response_message = payload;
  }),
  [verifyCartSuccess.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.disagreements = payload;
  }),
  [setChangeValue.toString()]: produce((draft: CartState, { payload }) => {
    draft.data.change_for_payment = payload;
  }),
}, INITIAL_STATE);
