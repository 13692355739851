import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    background-color: #ed4d40;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Description = styled.p`
    color: #f7f7f7;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    text-align: center;
    a {
        align-items: center;
        cursor: pointer;
        font-style: italic;
    }
`;
