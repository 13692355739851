import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useVerifiedStoreAction from 'hooks/useVerifiedStoreAction';

import { addProductToCartRequest, getAddedProductInCart, getSelectedStore } from 'store/ducks';
import { getConfig, getMarketPlaceCode } from 'store/ducks/base';

import { Product } from 'typing/models';

import { getProductImageUrl } from 'utils/image';

import IconExchange from '../IconExchange';
import ProductPrice from '../ProductPrice';
import QuantityControl from '../QuantityControl';
import {
  ProductCardContainer,
  CardInner,
  ImageContainer,
  ImageInner,
  Image,
  Info,
  Name,
  NameLink,
  Actions,
  Button,
  Flag,
} from './ProductCard.styled';

interface ProductCardProps {
  product: Product;
  selectProduct(): void;
  modifiers?: any;
  exchangeFlag?: boolean
}

export default function ProductCard(
  {
    product, selectProduct, modifiers, exchangeFlag,
  }: ProductCardProps,
): React.ReactElement {
  const dispatch = useDispatch();
  const item = useSelector(getAddedProductInCart(product.product_child?.id));
  const marketplace = useSelector(getMarketPlaceCode);
  const store = useSelector(getSelectedStore);

  const storeSlug = useMemo(() => store?.slug || 'undefined', [store]);

  const addVerifiedAction = useVerifiedStoreAction(
    storeSlug, (): any => dispatch(addProductToCartRequest({ product, quantity: 1 })),
  );

  const selectVerifiedAction = useVerifiedStoreAction(storeSlug, selectProduct);

  const { image_product_placeholder: placeholder } = useSelector(getConfig);

  let productUrl: string;
  if (product.images) {
    const listImages = JSON.parse(product.images);
    if (listImages.length > 0) {
      productUrl = listImages[0].image.url;
    } else {
      productUrl = getProductImageUrl(storeSlug, product.id, marketplace);
    }
  } else {
    productUrl = getProductImageUrl(storeSlug, product.id, marketplace);
  }

  return (
    <ProductCardContainer modifiers={modifiers}>
      <CardInner>
        {exchangeFlag ? <Flag><IconExchange size={1.5} /></Flag> : null}
        <ImageContainer onClick={selectVerifiedAction}>
          <ImageInner
            style={{
              position: 'relative',
              borderRadius: '15px 15px 0px 0px',
              overflow: 'hidden',
            }}
          >
            <Image
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                left: '50%',
              }}
              src={productUrl}
              {...{ placeholder: placeholder ?? undefined }}
            />
          </ImageInner>
        </ImageContainer>
        <Info
          style={{
            height: !product.has_variants ? '110px' : '96px',
          }}
        >
          <Name>
            <NameLink onClick={selectProduct}>
              {product.name}
            </NameLink>
          </Name>
          <ProductPrice product={product} />
          {
            !product.has_variants && (
              <Actions style={{ display: 'block' }}>
                {
                  item ? (
                    <QuantityControl item={item} />
                  ) : (
                    <Button
                      onClick={addVerifiedAction}
                      style={{ color: '#28a745!important', height: '32px' }}
                      data-ol-has-click-handler
                    >
                      Adicionar
                    </Button>
                  )
                }
              </Actions>
            )
          }
        </Info>
      </CardInner>
    </ProductCardContainer>
  );
}
