import capitalize from 'lodash/capitalize';
import first from 'lodash/first';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useUrls from 'hooks/useUrls';

import { getProductsFilters, getSelectedCategory } from 'store/ducks';
import { getSelectedProduct, getFieldForExtraData } from 'store/ducks/products';

import { Store } from 'typing/models';

import {
  BreadcrumbContainer,
  Item,
  ItemLink,
} from './Breadcrumb.styled';

interface BreadcrumbProps {
  store?: Store | null;
}

export default function Breadcrumb({ store }: BreadcrumbProps): React.ReactElement {
  const { linkId } = useParams();
  const { getStoreUrl, getLinkUrl, getCampaignUrl } = useUrls();

  const category = useSelector(getSelectedCategory);
  const product = useSelector(getSelectedProduct);
  const filter = useSelector(getProductsFilters('search'));
  const linkedTitle = useSelector(getFieldForExtraData('link'));
  const linkedCode = useSelector(getFieldForExtraData('link', 'code'));
  const campaignTitle = useSelector(getFieldForExtraData('campaign', 'name'));
  const campaignId = useSelector(getFieldForExtraData('campaign', 'id'));

  const isInExchange = useSelector(getFieldForExtraData('exchange', 'fetched'));

  const selectedProductCategory = first(product?.category_ids);

  return (
    <>
      {
        !!store && (
        <BreadcrumbContainer>
          <Item><ItemLink to={getStoreUrl()}>{store?.name}</ItemLink></Item>
          {!!linkedTitle && !filter?.searchName && (
            <Item>
              <ItemLink to={getLinkUrl(linkedCode)}>
                <strong className="result">{linkedTitle}</strong>
              </ItemLink>
            </Item>
          )}
          {!!campaignTitle && campaignId && !filter?.searchName && (
            <Item>
              <ItemLink to={getCampaignUrl(campaignId)}>
                <strong className="result">{campaignTitle}</strong>
              </ItemLink>
            </Item>
          )}
          {
            (!linkId && !isInExchange && ((!!category && !filter?.searchName) || selectedProductCategory) && !filter?.searchName)
              ? (
                <Item>
                  <strong>{capitalize(category?.name || selectedProductCategory?.name)}</strong>
                </Item>
              ) : null
          }
          {isInExchange && !filter?.searchName ? (
            <Item>
              Produtos para trocas
            </Item>
          ) : null}
          {!!filter?.searchName && (
          <Item>
            Resultados para:
            {' '}
            <strong className="result">{filter?.searchName}</strong>
          </Item>
          )}

        </BreadcrumbContainer>
        )
      }
    </>
  );
}
