import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const ContainerModifiers = {
  XSmall: () => `
    max-width: ${rem('414px')};
  `,
  MiddleSmall: () => `
  max-width: ${rem('500px')};
`,
  Small: () => `
    max-width: ${rem('600px')};
  `,
  TextCenter: () => `
    text-align: center;
  `,
};

const TitleModifiers = {
  Small: () => `
    font-size: ${rem('20px')};
    @media ${media.tabletMin} {
      font-size: ${rem('24px')};
    }
  `,
};

const ListOrderModifiers = {
  Completed: ({ theme }: any) => `
    color: #06C167;
    font-weight: bold;
  `,
};

const PageHeaderModifiers = {
  Center: () => `
    text-align: center;
  `,
};

const NavTabModifiers = {
  Active: ({ theme }: any) => `
    border-bottom: 1px solid ${theme.primary.main};
    color: ${theme.primary.main} !important;
    font-weight: bold;
  `,
};

const TabBodyModifiers = {
  Active: () => `
    display: block;
  `,
};

export const Page = styled.div`
  padding-top: 7rem;
  padding-bottom: 6rem;
  @media ${media.tabletMin} {
    padding-top: 8rem;
  }
`;

export const Container = styled.div.attrs({
  className: 'container',
})<utils.WithModifiers>`
  ${applyStyleModifiers(ContainerModifiers)};
`;

export const PageHeader = styled.div.attrs({
  className: 'heading_s2',
})<utils.WithModifiers>`
  margin-bottom: ${rem('24px')};
  ${applyStyleModifiers(PageHeaderModifiers)};
`;

export const PageBody = styled.div`
`;

export const Title = styled.h2.attrs({
})<utils.WithModifiers>`
  ${applyStyleModifiers(TitleModifiers)};
`;

export const Text = styled.p`
`;

export const ProductList = styled.div.attrs({
  className: 'product_list',
})`

@media ${media.mobileMin} {
  display: flex;
  flex-wrap: wrap;
}

.item {
  @media ${media.mobileMin} {
    width: calc(100% / 2 - 12px);
  }
  @media ${media.tabletMin} {
    width: calc(100% / 3 - 1rem);
  }
  @media ${media.desktopMin} {
    width: calc(100% / 4 - 1rem);
  }
}

.product {
  min-height: ${rem('145px')};
  @media ${media.mobileMin} {
    flex-direction: column;
  }

  .product_img {
    align-self: flex-start;
    @media ${media.mobileMin} {
      align-self: center;
    }
    
    > div {
      padding: 0
    }
    
    img {
      height: auto;
      @media ${media.mobileMin} {
        height: ${rem('140px')};
      }
    }

  }

  .product_info {
    @media ${media.mobileMin} {
      max-width: 100%;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }
  }

}
`;

export const Panel = styled.div`
  background-color: #f7f7f7;
  border-radius: 16px;
  padding: 1.25rem 1.25rem .5rem;
  margin-bottom: 1rem;
  min-height: ${rem('175px')};
`;

export const PanelHeader = styled.div`
  margin-bottom: 1rem;
  h2 {
    font-size: ${rem('20px')};
    font-weight: bold;
  }
`;

export const PanelBody = styled.div`
`;

export const ListProgress = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ItemProgress = styled.li.attrs({
})<utils.WithModifiers>`
  color: black;
  font-size: ${rem('16px')};
  svg {
    height: ${rem('24px')};
    margin-right: .5rem;
    stroke: #06C167;
    stroke-width: 3px;
    width: ${rem('24px')};
  }
  &:not(last-child) {
    margin-bottom: .5rem;
  }
  ${applyStyleModifiers(ListOrderModifiers)}
`;

export const ButtonBack = styled(Link)`
  display: none;
  margin: 0 0 .5rem;
  @media ${media.tabletMax} {
    display: inline-block;
  }
`;

export const TabHeader = styled.div`
  border-bottom: 1px solid #DDD;
  display: flex;
  margin-bottom: 1rem;
  @media ${media.mobileMax} {
    margin-bottom: 2rem;
  }
`;

export const NavTab = styled.a.attrs({
})<utils.WithModifiers>`
  cursor: pointer;
  display: block;
  font-size: 16px;
  flex: 1;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  ${applyStyleModifiers(NavTabModifiers)};
`;

export const TabBody = styled.div.attrs({
})<utils.WithModifiers>`
  display: none;
  ${applyStyleModifiers(TabBodyModifiers)};
`;
