import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    border-radius: 16px;
    background-color: #f7f7f7;
    padding: 8px 16px;
    flex-direction: column;
`;

export const Title = styled.span`
    color: #292b2c;
    font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins', sans-serif;
`;

export const Currency = styled.strong`
    color: #292B2C;
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
        opacity: 0.5;
        font-size: 15px;
        line-height: 23px;
    }
`;

interface DescriptionProps {
    variant?: 'danger';
  }

export const Description = styled.p<DescriptionProps>`
  color: ${({ variant }) => (variant === 'danger' ? '#E71A1A' : '#292B2C')};
  opacity:  ${({ variant }) => (variant === 'danger' ? 1 : 0.5)};
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
`;
