import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const ColProducts = styled.div.attrs({
  className: 'col-lg-9',
})`
  padding-left: 0;
  padding-right: 0;
`;

export const Sidebar = styled.aside`
`;

export const Banner = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #292B2C;
  border-radius: 16px;
  padding: 8px 32px;
  margin-bottom: 32px;
  height: 138px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center; 
 

  strong {
    color: #F7F7F7;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700px;
    font-family: 'Poppins', sans-serif;

  }
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const NoResult = styled.h2`
  text-align: center;
  @media ${media.tabletMax} {
    font-weight: bold;
    text-align: left;
  }
  @media ${media.desktopMin} {
    margin-top: 4rem;
  }
`;

export const ShareMessage = styled.p`
  color: ${(props) => props.theme.primary.main};
  font-size: ${rem('16px')};
  font-weight: bold;
  text-align: center;
`;
