import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
  addProductToCartRequest,
  getAddedProductInCart,
  getCart,
  getSelectedStore,
  isLoading,
  hasError,
  syncCartRequest,
} from 'store/ducks';
import { clearCart, clearCartItems } from 'store/ducks/cart';
import { getProductRequest, getSelectedProduct } from 'store/ducks/products';

import { CartItem, ProductChild } from 'typing/models';

import FeedbackMessage from 'ui/components/FeedbackMessage';
import ModalDiscardCart from 'ui/components/ModalDiscardCart';
import ProductGallery from 'ui/components/ProductGallery';
import QuantityControl from 'ui/components/QuantityControl';
import SliderContainer from 'ui/components/SliderContainer';
import SpinnerLoading from 'ui/components/SpinnerLoading';
import StorePageHeaderCategory from 'ui/components/StorePageHeaderCategory';

import { SYNC_CART } from 'utils/fetchs';
import { numberToMoney } from 'utils/monetary';

import useVerifiedStoreAction from '../../../hooks/useVerifiedStoreAction';
import {
  Main,
  Section,
  Container,
  Row,
  Col,
  Detail,
  Description,
  ProductTitle,
  ProductPrice,
  Price,
  ProductInfo,
  ProductInfoTitle,
  Content,
  ProductMeta,
  ProductSizes,
  ProductSizeItem,
  CartAddContainer,
  ButtonContainer,
  Button,
  SelectSize,
  SelectColors,
  Select,
} from './ProductDetail.styled';

export default function ProductDetail(): React.ReactElement {
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const { linkId, productId } = useParams();

  const { state } = useLocation<{exchange?: boolean;}>();

  const [selectedVariant, setSelectedVariant] = useState<ProductChild | undefined>();
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedStock, setSelectedStock] = useState<string | null>(null);

  const [showQuantityError, setShowQuantityError] = useState(false);

  const getItemInCart = useCallback(() => getAddedProductInCart(selectedVariant?.id), [selectedVariant]);

  const [reloadCart, setReloadCart] = useState<boolean>(true);
  const loading = useSelector(isLoading(SYNC_CART, productId));
  const store = useSelector(getSelectedStore);
  const item = useSelector(getItemInCart());
  const product = useSelector(getSelectedProduct);
  const blockAddItemFromOtherLocations = !!store?.config?.block_add_item_from_other_locations
        && cart.items.filter((i) => String(i.location_code) !== String(selectedStock)).length > 0
        && !!selectedStock;

  const hasErrorOnSync = useSelector(hasError(SYNC_CART, product?.id));

  const isAllowedLocationCode = useMemo(() => {
    if (!selectedStock || cart.items.length === 0) {
      return true;
    }
    return cart.items.filter((i) => String(i.location_code) === String(selectedStock)).length > 0;
  }, [selectedStock, cart.items]);

  const productPrices = useMemo(() => {
    if (selectedVariant) {
      return {
        promo_price: selectedVariant.promo_price,
        base_price: selectedVariant.price,
      };
    }
    if (product) {
      return {
        promo_price: product.promo_price,
        base_price: product.base_price,
      };
    }
    return undefined;
  }, [selectedVariant, product]);

  useEffect(() => {
    dispatch(syncCartRequest('only_download'));
    setReloadCart(false);
  }, [reloadCart]);

  useEffect(() => {
    if (hasErrorOnSync) {
      setShowQuantityError(true);
    }
  }, [hasErrorOnSync]);

  useEffect(() => {
    if (!product || productId !== product.id.toString()) {
      dispatch(getProductRequest({ id: productId, storeSlug: store?.slug }));
    }
  }, [product, productId]);

  const getVariantsAttributes = (variants: ProductChild[]) => groupBy(
    uniqBy(
      flatten(variants.map((variant) => variant.child_attribute_values)),
      (attribute) => `${attribute.attribute_code}|${attribute.value}`,
    ),
    'attribute_code',
  );

  const [showModal, setShowModal] = useState(false);

  const handleReject = () => {
    setShowModal(false);
  };

  const addProduct = useCallback(() => {
    dispatch(
      addProductToCartRequest(
        {
          product: { ...product, product_child: selectedVariant },
          location_code: selectedStock,
          quantity: 1,
          flag: state?.exchange ? 'exchange_item' : undefined,
        },
        productId,
      ),
    );
  }, [product, selectedVariant, selectedStock]);

  const addToCart = useCallback(() => {
    const isExchangeCart = cart.items.length > 0 && cart.items.every((productItem) => productItem.product.flag === 'exchange_item');
    if (!state?.exchange && isExchangeCart) {
      setShowModal(true);
      return;
    }
    addProduct();
  }, [cart, addProduct]);

  const handleAccept = async (clean = true) => {
    setShowModal(false);
    if (clean) {
      await dispatch(clearCartItems());
      await dispatch(syncCartRequest());
    }
    addProduct();
  };

  const addNewProduct = useVerifiedStoreAction(store?.slug || 'undefined', addToCart);

  const allAttributes = useMemo(() => {
    if (product) {
      let variants = product.product_variant_children;

      const attributes = getVariantsAttributes(variants);

      if (selectedColor) {
        variants = variants.filter((variant) => variant.child_attribute_values.find(
          (attr) => attr.attribute_code === 'COR' && attr.value === selectedColor,
        ));
        attributes.TAMANHO = getVariantsAttributes(variants).TAMANHO;
      } else if (!selectedColor && selectedSize) {
        variants = variants.filter((variant) => variant.child_attribute_values.find(
          (attr) => attr.attribute_code === 'TAMANHO' && attr.value === selectedSize,
        ));
        attributes.COR = getVariantsAttributes(variants).COR;
      }

      return attributes;
    }

    return { COR: [], TAMANHO: [] };
  }, [product, selectedColor, selectedSize]);

  useEffect(() => {
    if (allAttributes.COR.length === 1 && !selectedColor) {
      setSelectedColor(allAttributes.COR[0].value);
    }
  }, [product, allAttributes.COR.length, selectedColor]);

  useEffect(() => {
    if (product) {
      const variant = product.product_variant_children.find(
        (variant) => !!variant.child_attribute_values.find(
          (attr) => attr.attribute_code === 'TAMANHO' && attr.value === selectedSize,
        )
                    && variant.child_attribute_values.find(
                      (attr) => attr.attribute_code === 'COR' && attr.value === selectedColor,
                    ),
      );

      setSelectedVariant(variant);
      if (variant) {
        setSelectedStock(variant.stock[0].location_code.toString());
      }
    }
  }, [product, selectedColor, selectedSize]);

  const AddButton = (): React.ReactElement => {
    if (loading) {
      return <SpinnerLoading />;
    }

    if (item) {
      return (
        <QuantityControl
          item={item}
          selectedStockItem={
                        selectedVariant
                          ? selectedVariant.stock.find((i) => i.location_code.toString() == selectedStock)
                          : null
                    }
        />
      );
    }

    return (
      <ButtonContainer>
        <Button
          disabled={!selectedVariant || blockAddItemFromOtherLocations || !isAllowedLocationCode}
          onClick={addNewProduct}
          type="button"
        >
          Adicionar ao carrinho
        </Button>
      </ButtonContainer>
    );
  };
  return (
    <Main>
      <StorePageHeaderCategory store={store} />
      <Section>
        <Container>
          <Row className="row">
            <Col className="col-lg-4 col-md-6 mb-3 mb-md-0">
              <SliderContainer>
                <ProductGallery product={product} />
              </SliderContainer>
            </Col>
            <Col className="col-lg-6 col-md-6 pl-md-4 pl-lg-5">
              <Detail>
                <Description>
                  <div>
                    <ProductTitle>{product?.name}</ProductTitle>
                  </div>
                  <p>
                    {selectedVariant && (
                    <>
                      Código do produto:
                      {' '}
                      {selectedVariant
                        ? selectedVariant.ean13
                        : product?.product_child?.ean13}
                    </>
                    )}
                  </p>
                  <ProductPrice>
                    {productPrices?.promo_price ? (
                      <>
                        <Price>{numberToMoney(productPrices?.promo_price)}</Price>
                        <del>{numberToMoney(productPrices?.base_price)}</del>
                      </>
                                        ) : (
                                          <Price>{numberToMoney(productPrices?.base_price)}</Price>
                                        )}
                  </ProductPrice>
                  <div className="clearfix" />
                  <br />
                  <ProductInfo>
                    <ProductInfoTitle>Cores</ProductInfoTitle>
                    <ProductSizes>
                      {sortBy(allAttributes.COR, 'value').map((option, index) => (
                        <SelectColors
                          type="button"
                          key={index}
                          onClick={() => (selectedColor === option.value
                            ? setSelectedColor(null)
                            : setSelectedColor(option.value))}
                        >
                          <ProductSizeItem
                            modifiers={option.value === selectedColor ? 'Active' : ''}
                          >
                            {option.value}
                          </ProductSizeItem>
                        </SelectColors>
                      ))}
                    </ProductSizes>
                  </ProductInfo>
                  <ProductInfo>
                    <ProductInfoTitle>Tamanhos</ProductInfoTitle>
                    <ProductSizes>
                      {sortBy(allAttributes.TAMANHO, 'value').map((option, index) => (
                        <SelectSize
                          type="button"
                          key={index}
                          onClick={() => (selectedSize === option.value
                            ? setSelectedSize(null)
                            : setSelectedSize(option.value))}
                        >
                          <ProductSizeItem
                            modifiers={option.value === selectedSize ? 'Active' : ''}
                          >
                            {option.value}
                          </ProductSizeItem>
                        </SelectSize>
                      ))}
                    </ProductSizes>
                  </ProductInfo>
                  {selectedVariant && (
                    <ProductInfo>
                      <ProductInfoTitle>Comprar de:</ProductInfoTitle>
                      <Select
                        defaultValue={item?.location_code}
                        onChange={(ev): void => {
                          setSelectedStock(ev.target.value.toString());
                        }}
                      >
                        {selectedVariant.stock.map((stock) => (
                          <option key={stock.location_code} value={stock.location_code}>
                            {stock.location_name}
                          </option>
                        ))}
                      </Select>
                    </ProductInfo>
                  )}
                </Description>
                <br />
                <Content>
                  <p>{product?.description}</p>
                </Content>
                <CartAddContainer>
                  <AddButton />
                  <FeedbackMessage modifiers={!isAllowedLocationCode ? ['ErrorBar', 'Active'] : []}>
                    Desculpe, mas parece que há itens em seu carrinho de compras de outra loja.
                    Tente finalizar a compra ou remover os itens do carrinho.
                  </FeedbackMessage>
                  <FeedbackMessage modifiers={showQuantityError ? ['ErrorBar', 'Active'] : []}>
                    Desculpe, mas este produto não está disponível nesta quantidade no momento,
                    recomendamos que você recarregue a página.
                  </FeedbackMessage>
                </CartAddContainer>

                <hr />
                <ProductMeta>
                  <li>
                    Categorias: &nbsp;
                    {product?.category_ids.map((category) => (
                      <a
                        key={category.id}
                        href={`/store/${store?.slug}/search?categoryId=${category.id}`}
                      >
                        {category.name}
                      </a>
                    ))}
                  </li>
                </ProductMeta>
              </Detail>
            </Col>
          </Row>
        </Container>
      </Section>
      {showModal ? (
        <ModalDiscardCart
          onReject={handleReject}
          onAccept={handleAccept}
        />
      ) : null}
    </Main>
  );
}
