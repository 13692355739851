import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChgIcon from 'assets/images/change-banner.svg';

import { isLoading } from 'store/ducks';
import { listExchangeProductsRequest } from 'store/ducks/products';

import { LIST_EXCHANGE_PRODUCTS } from 'utils/fetchs';

import NavCategories from '../NavCategories';
import ProductsFull from '../ProductsFull';
import SidebarCategories from '../SidebarCategories';
import {
  Col, Container, Row, Sidebar, Banner,
} from './ExchangeProducts.styled';

export default function ExchangeProducts(): React.ReactElement {
  const dispatch = useDispatch();

  const loading = useSelector(isLoading(LIST_EXCHANGE_PRODUCTS));

  useEffect(() => {
    dispatch(listExchangeProductsRequest([1]));
  },
  [listExchangeProductsRequest, dispatch]);

  return (
    <Container>
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <Row className="row">
          <NavCategories fullCategories={false} />
          <Container>
            <Banner style={{
              backgroundImage: `url(${ChgIcon})`,
            }}
            >
              <strong>Produtos para troca</strong>
            </Banner>
            <ProductsFull key={1} categoryId={0} showFilter={false} exchange />
          </Container>
          <Col
            className="col-lg-3 order-lg-first mt-lg-0 pt-lg-0 order-first"
            style={{
              position: 'sticky',
              top: '100px',
              alignSelf: 'flex-start',
              zIndex: 10,
            }}
          >
            <Sidebar>
              <SidebarCategories fullCategories={false} />
            </Sidebar>
          </Col>
        </Row>
      )}
    </Container>
  );
}
