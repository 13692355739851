import _ from 'lodash';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import CurrencyInput from 'react-currency-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  clearCartItems,
  getCart,
  getCartTotal,
  getCurrentPaymentMethod,
  getPaymentMethods,
  setChangeValue,
  setPaymentMethod,
  syncCartRequest,
} from 'store/ducks/cart';
import { getRealExchangeBalance } from 'store/ducks/user';

import { PanelHeader } from 'ui/components/Page';

import { AddCardSchemaType } from 'utils/schemas';

import { Card } from '../../../typing/models';
import { PAYMENT_TYPE_ONLINE_CREDIT_CARD } from '../../../utils/contants';
import { numberToMoney } from '../../../utils/monetary';
import ModalDiscardCart from '../ModalDiscardCart/ModalDiscardCart';
import AddCard from './AddCard';
import CardList from './CardList';
import { PaymentContainer } from './Payment.styled';

interface PaymentProps {
    cards?: Card[];
    setup?: object;
    onClickAddCard(card: AddCardSchemaType): void;
    onClickRemoveCard(index: AddCardSchemaType): void;
    responseCreditCards?: object | null;
    clickedFinish: boolean;
    totalCards: number;
}

export default function Payment({
  cards,
  setup,
  onClickAddCard,
  onClickRemoveCard,
  responseCreditCards,
  clickedFinish,
  totalCards,
}: PaymentProps): React.ReactElement {
  const dispatch = useDispatch();

  const cart = useSelector(getCart);
  const total = useSelector(getCartTotal);
  const { change_for_payment } = useSelector(getCart);
  const methods = useSelector(getPaymentMethods);
  const currentMethod = useSelector(getCurrentPaymentMethod);
  const kind = currentMethod?.default_code;
  const paymentType = currentMethod?.payment_type;
  const maxTotalCard = _.get(setup, 'max_total_card', 1);
  const sumAmountCards = _.sumBy(cards, 'amount');

  const isExchangeCart = cart.items.every((item) => item.product.flag === 'exchange_item');

  const { balance, expired } = useSelector(getRealExchangeBalance);
  const cartTotal = useSelector(getCartTotal);

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const selectRef = useRef<HTMLSelectElement>(null);

  const isEnoughExchangeBalance = useMemo(() => balance >= cartTotal && !expired, [balance, expired, cartTotal]);

  const handleAccept = async () => {
    setShowModal(false);
    history.push('/');
    await dispatch(clearCartItems());
    await dispatch(syncCartRequest());
  };
  const handleReject = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(setPaymentMethod(null));
    dispatch(setChangeValue(null));
  }, []);

  const setValueInSelect = (value?: string) => {
    if (selectRef.current) {
      selectRef.current.value = value ?? '-1';
    }
  };

  useEffect(() => {
    if (isExchangeCart) {
      const voucherMethod = _.find(methods, (method) => method.payment_type === 'voucher');
      if (voucherMethod) {
        if (selectRef.current) {
          setValueInSelect(voucherMethod.id);
        }
        dispatch(setPaymentMethod(voucherMethod));
        dispatch(setChangeValue(null));
      }
    }
  }, [isExchangeCart, methods, selectRef]);

  const handleChangePayment = (ev) => {
    const value = ev.target.value.toString();

    const selectedMethod = _.find(methods, (method) => method.id.toString() === value);

    if (currentMethod !== selectedMethod) {
      if ((isExchangeCart && selectedMethod?.payment_type !== 'voucher')
        || (!isExchangeCart && selectedMethod?.payment_type === 'voucher')) {
        setShowModal(true);
        setValueInSelect(currentMethod?.id);
        return;
      }
    }

    dispatch(setPaymentMethod(selectedMethod));
    dispatch(setChangeValue(null));
  };

  return (
    <PaymentContainer>
      <PanelHeader>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="payment_method">Meio de Pagamento</label>
            <select ref={selectRef} id="payment_method" className="form-control" onChange={handleChangePayment}>
              <option value={-1} key="default" selected>
                SELECIONE UMA OPÇÃO
              </option>
              {methods.map((method) => (
                <option value={method.id} key={method.default_code}>
                  {method.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </PanelHeader>
      {!isEnoughExchangeBalance ? (
        <div className="alert alert-danger" role="alert">
          O valor total do pedido de troca não pode ser maior que o saldo válido disponível.
          <br />
          Seu saldo de troca não é suficiente para o valor do pedido.
        </div>
      ) : null }
      {(currentMethod?.min_amount || 0) > total && (
        <div className="alert alert-warning" role="alert">
          Para ativar esse modo o valor minimo do pedido é:
          {' '}
          <strong>{numberToMoney(currentMethod?.min_amount)}</strong>
        </div>
      )}

      {(currentMethod?.min_items_count || 0) > _.sumBy(cart.items, 'quantity') && (
        <div className="alert alert-warning" role="alert">
          Para ativar esse modo a quantidade minima de itens é:
          {' '}
          <strong>{currentMethod?.min_items_count}</strong>
        </div>
      )}

      {paymentType === PAYMENT_TYPE_ONLINE_CREDIT_CARD && (
        <>
          {maxTotalCard > 1 && (
          <div className="alert alert-success" role="alert">
            <strong>
              Utilize até
              {maxTotalCard}
              {' '}
              cartões para realizar o pagamento.
            </strong>
          </div>
          )}

          {_.get(setup, 'max_total_card') > (cards || []).length && sumAmountCards !== total && (
          <AddCard onClickAddCard={onClickAddCard} totalCards={totalCards} cards={cards} setup={setup} />
          )}
          <CardList
            clickedFinish={clickedFinish}
            cards={cards}
            responseCreditCards={responseCreditCards}
            onClickRemoveCard={onClickRemoveCard}
          />
        </>
      )}

      {kind === 'DINH' && (
        <PanelHeader>
          <h2>Troco para pagamento?</h2>
          <p>Adicione abaixo o valor de troco:</p>

          <CurrencyInput
            prefix="R$ "
            value={change_for_payment}
            className="form-control"
            placeholder="Ex: Troco para R$ 50"
            name="amount"
            id="amount"
            type="tel"
            selectAllOnFocus
            decimalSeparator=","
            thousandSeparator="."
            onChange={(ev, maskedValue): void => {
              dispatch(setChangeValue(maskedValue));
            }}
          />
        </PanelHeader>
      )}
      {showModal && (
        <ModalDiscardCart onReject={handleReject} onAccept={handleAccept} />
      )}
    </PaymentContainer>
  );
}
