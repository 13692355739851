import { Cart } from 'typing/models';
import { CartRequestBody } from 'typing/request';

export const parseCartToCartRequestBody = (cart: Cart): CartRequestBody => {
  const reqBody: CartRequestBody = {
    store: cart.store?.slug,
    picking_type: 'delivery',
    shipping_method: cart.shipping_method,
    delivery_address: cart.delivery_address?.id,
    payment_type: cart.payment_type?.id,
    change_for_payment: cart.change_for_payment,
    cart_items: cart.items.map((item) => ({
      product_id: item.product.product_child.id.toString(),
      product_name: item.product.name,
      location_code: item.location_code ? item.location_code.toString() : null,
      flag: item.flag,
      quantity: item.quantity.toString(),
      suggest_price: (item.product.product_child.promo_price ?? item.product.product_child.price)
        .toFixed(2).toString(),
    })),
  };

  if (cart.id) {
    reqBody.id = cart.id;
  }

  if (cart.client) {
    reqBody.client = cart.client;
  }

  if (cart.state) {
    reqBody.state = cart.state;
  }

  if (cart.expiration_date) {
    reqBody.expiration_date = cart.expiration_date;
  }

  if (cart.notes) {
    reqBody.notes = cart.notes;
  }

  return reqBody;
};
