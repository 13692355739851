import {
  ShoppingBag,
  X as IconClose,
} from '@styled-icons/feather';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SettingsContext from 'context/SettingsContext';

import useUrls from 'hooks/useUrls';

import {
  getCart,
  getCartSubTotal, isLogged, isSuccess, hasError,
  loadCartRequest, verifyCartClean, verifyCartRequest, syncCartRequest,
} from 'store/ducks';
import { getConfig, getMarketPlaceCode } from 'store/ducks/base';
import { getCartNotifications, getDisagreements } from 'store/ducks/cart';

import { CartNotificationMessage, Product as ProductType } from 'typing/models';

import { EMPTY_CART_SUBTITLE, EMPTY_CART_TITLE } from 'utils/contants';
import { VERIFY_CART } from 'utils/fetchs';
import { getProductImageUrl } from 'utils/image';
import { numberToMoney } from 'utils/monetary';

import FeedbackMessage from '../FeedbackMessage';
import IconExchange from '../IconExchange';
import QuantityControl from '../QuantityControl';
import {
  ShoppingCartContainer,
  CardHeader,
  CloseCart,
  CartList,
  Item,
  Product,
  ProductImage,
  ProductActions,
  CartQuantity,
  CartFooter,
  CartTotal,
  CartButtons,
  Button,
  CartInner,
  EmptyMessage,
  VariantContainer,
  Variant,
  ProdContent,
  Thumbnail,
  Caption,
  Stock,
  CartIcon,
} from './ShoppingCart.styled';

export default function ShoppingCart(): React.ReactElement {
  const { isVisibleModal, closeModal } = useContext(SettingsContext);
  const history = useHistory();
  const { getProductUrl } = useUrls();

  const modifiers = (isVisibleModal && isVisibleModal('CART')) ? ['show'] : [];
  const notifications = useSelector(getCartNotifications);

  const dispatch = useDispatch();

  useEffect((): any => {
    dispatch(loadCartRequest());

    return (): void => { dispatch(verifyCartClean()); };
  }, [dispatch]);

  const cart = useSelector(getCart);
  const subTotal = useSelector(getCartSubTotal);
  const authorized = useSelector(isLogged);
  const marketplace = useSelector(getMarketPlaceCode);

  const {
    image_product_placeholder: placeholder,
    ...otherConfigs
  } = useSelector(getConfig);

  const { cart_icon } = get(otherConfigs, 'config.images', {});

  const {
    empty_cart_title = EMPTY_CART_TITLE,
    empty_cart_subtitle = EMPTY_CART_SUBTITLE,
  } = get(otherConfigs, 'config.labels', {});

  const success = useSelector(isSuccess(VERIFY_CART));
  const error = useSelector(hasError(VERIFY_CART));
  const disagreements = useSelector(getDisagreements);

  useEffect(() => {
    if (success) {
      if (authorized && !disagreements.length) {
        history.push('/checkout/');
      } else if (authorized) {
        history.push('/checkout/fix');
      } else {
        const next = !disagreements.length ? '/checkout/' : '/checkout/fix';
        history.push(`/profile/login?next=${next}`);
      }
    }
    if (error) {
      dispatch(syncCartRequest());
    }
  }, [success, authorized, error]);

  const closeCart = useCallback((): void => {
    dispatch(verifyCartRequest());
  }, []);

  const getItemNotifications = useCallback((productId: string | number):
    CartNotificationMessage[] | undefined => {
    const notification = notifications.find((alert) => !!alert[productId.toString()]);

    return notification ? notification[productId.toString()] : undefined;
  }, [notifications]);

  const getItemUnitValue = (product: ProductType):
    number => product.product_child.promo_price ?? product.product_child.price;

  const renderFlag = (product: ProductType) => {
    if (product.flag === 'exchange_item') {
      return (
        <FeedbackMessage modifiers={['Active', 'Gray']}>
          <IconExchange size={1.5} />
          Produto para uso do saldo de troca
        </FeedbackMessage>
      );
    }
  };

  return (

    <ShoppingCartContainer modifiers={modifiers}>
      {
        !cart.items.length ? (
          <CartInner>
            <CardHeader>
              <CloseCart onClick={closeModal}><IconClose /></CloseCart>
            </CardHeader>
            <EmptyMessage>
              {
                cart_icon ? <CartIcon src={cart_icon} /> : <ShoppingBag />
              }
              <h2>{empty_cart_title}</h2>
              <p>{empty_cart_subtitle}</p>
            </EmptyMessage>
          </CartInner>
        ) : (
          <>
            <CardHeader>
              <h5>
                Comprando de:
                <span>{cart.store?.name}</span>
              </h5>
              <CloseCart onClick={closeModal}><IconClose /></CloseCart>
            </CardHeader>
            <CartList>
              {
                cart.items.map((item) => (
                  <Item key={item.product.product_child.id}>
                    <Product>
                      <ProdContent>
                        <Thumbnail to={getProductUrl(item.product)}>
                          <ProductImage
                            src={getProductImageUrl(cart?.store?.slug || '', item.product.id, marketplace)}
                            alt="cart_thumb1"
                            {...{ placeholder: placeholder ?? undefined }}
                          />
                        </Thumbnail>
                        <Caption>
                          {item.product.product_child.name}
                          {
                            item.product.has_variants && (
                              <VariantContainer>
                                {item.product.product_child.child_attribute_values.map(
                                  (attrValue) => {
                                    if (attrValue.attribute_code !== 'PERSONA'
                                  && attrValue.attribute_code !== 'COLECAO') {
                                      return (
                                        <Variant key={attrValue.attribute_code}>
                                          {capitalize(attrValue.attribute_code)}
                                          :
                                          {' '}
                                          {attrValue.value}
                                        </Variant>
                                      );
                                    }
                                    return null;
                                  },
                                )}
                              </VariantContainer>
                            )
                          }
                          {renderFlag(item.product)}
                          {
                          getItemNotifications(item.product.product_child.id)?.map(
                            (notification: CartNotificationMessage, index) => (
                              <FeedbackMessage key={index} modifiers={['Active', 'Danger']}>
                                {notification.message}
                              </FeedbackMessage>
                            ))
                        }

                          <ProductActions>
                            <CartQuantity>
                              <strong>
                                {item.quantity}
                                x
                                {' '}
                              </strong>
                              {numberToMoney(getItemUnitValue(item.product))}
                            </CartQuantity>
                            <QuantityControl item={item} />
                          </ProductActions>
                          {
                        first(item.product.product_child.stock) && item.product.has_variants && (
                          <Stock>
                            Estoque:
                            {' '}
                            {
                            find(item.product.product_child.stock,
                              (stock) => stock.location_code.toString() === item.location_code)?.location_name || ''
}
                          </Stock>
                        )
                      }
                        </Caption>
                      </ProdContent>
                    </Product>
                  </Item>
                ))
              }
            </CartList>
            <CartFooter>
              {/* <CartTotal>
                <strong>Subtotal:</strong>
                {' '}
                {numberToMoney(subTotal)}
              </CartTotal>
              <CartTotal>
                <strong>Taxa de entrega:</strong>
                {' '}
                {numberToMoney(cart.delivery_fee)}
              </CartTotal>
              <CartTotal>
                <strong>Taxa de Serviço:</strong>
                {' '}
                {numberToMoney(cart.service_fee)}
              </CartTotal>
              <CartTotal modifiers="Last">
                <strong>Total:</strong>
                {' '}
                {numberToMoney(cart.delivery_fee + cart.service_fee + subTotal)}
              </CartTotal> */}
              <CartTotal modifiers="Last">
                <strong>Subtotal:</strong>
                {' '}
                {numberToMoney(subTotal)}
              </CartTotal>
              <CartButtons>
                <Button onClick={closeCart}>
                  Finalizar pedido
                </Button>
              </CartButtons>
            </CartFooter>
          </>
        )
      }
    </ShoppingCartContainer>
  );
}
