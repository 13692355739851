import { Action, ActionMeta } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import {
  call, put, takeEvery, select, take, race,
} from 'redux-saga/effects';

import {
  getProduct, listCampaignProducts, listExchangeProducts, listLinkedProducts, searchProducts,
} from 'services/api/products';

import {
  getProductsFilters,
  getSelectedStore,
  listProductsRequest,
  listProductsSuccess,
  listProductsFailure,
  searchProductsRequest,
  searchProductsSuccess,
  selectStore,
  getStoreSuccess,
  getSelectedCategory, getSelectedFiltersProduct,
} from 'store/ducks';
import {
  getProductRequest,
  getProductFailure,
  getProductSuccess,
  listLinkedProductsRequest,
  listExchangeProductsRequest,
  listExchangeProductsSuccess,
  listLinkedProductsSuccess,
  listCampaignProductsRequest,
  listCampaignProductsSuccess,
  listCampaignProductsFailure,
} from 'store/ducks/products';

import { ProductsFilter } from 'typing/store';

const DEFAULT_PAGE_LIMIT = 20;

type ActionListProducts = ActionMeta<number | null, { id?: number, page?: number }>;

function* listProductsAsync({ payload: categoryId, meta }: ActionListProducts): SagaIterator {
  let productsFilter = yield select(getProductsFilters('search'));
  const currentFiltersSelected = yield select(getSelectedFiltersProduct);
  const offset = (meta?.page || 0) * DEFAULT_PAGE_LIMIT;
  let limit = DEFAULT_PAGE_LIMIT;

  const selectedCategory = yield select(getSelectedCategory);
  const isSearchResult = !!productsFilter?.searchName && !selectedCategory;

  productsFilter = {
    ...productsFilter,
    extra_filters: currentFiltersSelected,
  };

  if (productsFilter?.searchName) {
    yield put(listProductsSuccess({ data: [], categoryId, search: isSearchResult }));
    limit = 100;
  }

  const fetchId = meta?.id ?? productsFilter?.searchName;

  try {
    let store = yield select(getSelectedStore);

    if (!store) {
      yield race([
        take(selectStore.toString()),
        take(getStoreSuccess.toString()),
      ]);
      store = yield select(getSelectedStore);
    }

    const { data } = yield call(
      searchProducts,
      store?.slug,
      categoryId ? [categoryId] : [],
      productsFilter,
      offset,
      limit,
    );

    yield put(listProductsSuccess(
      {
        data, categoryId, search: isSearchResult, append: !!meta?.page,
      }, fetchId,
    ));
  } catch (e) {
    yield put(
      listProductsFailure({ data: [], categoryId, search: !!productsFilter?.searchName }, fetchId),
    );
  }
}

type ActionListLinkedProducts = ActionMeta<string, { linkId?: number, page?: number }>;
function* listLinkedProductsAsync({ payload }: ActionListLinkedProducts): SagaIterator {
  try {
    let store = yield select(getSelectedStore);
    if (!store) {
      yield race([
        take(selectStore.toString()),
        take(getStoreSuccess.toString()),
      ]);
      store = yield select(getSelectedStore);
    }

    const linkId = payload[0];
    const page = parseInt(payload[1]);

    const data = yield call(listLinkedProducts, store?.slug, linkId, page);

    yield put(listLinkedProductsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(listLinkedProductsSuccess({
      items: [],
      code: 'error',
      id: 9999999,
      expiration_date: '2099-10-11T00:00:00.0000-03:00',
      store: 'store',
      title: 'Link',
    }));
    // yield put(listLinkedProductsFailure(e));
  }
}

function* listExchangeProductsAsync({ payload }: ActionListLinkedProducts): SagaIterator {
  try {
    let store = yield select(getSelectedStore);
    if (!store) {
      yield race([
        take(selectStore.toString()),
        take(getStoreSuccess.toString()),
      ]);
      store = yield select(getSelectedStore);
    }

    const page = parseInt(payload[0]);

    const data = yield call(listExchangeProducts, store?.slug, page);

    yield put(listExchangeProductsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(listExchangeProductsSuccess({
      data: [],

    }));
    // yield put(listLinkedProductsFailure(e));
  }
}

function* listCampaignProductsAsync({ payload: campaignId }: Action<string>): SagaIterator {
  try {
    let store = yield select(getSelectedStore);

    if (!store) {
      yield race([
        take(selectStore.toString()),
        take(getStoreSuccess.toString()),
      ]);
      store = yield select(getSelectedStore);
    }

    const data = yield call(listCampaignProducts, store?.slug, campaignId);

    yield put(listCampaignProductsSuccess(data));
  } catch (e) {
    yield put(listCampaignProductsFailure(e));
  }
}

function* searchProductsAsync({ payload }: Action<ProductsFilter>): SagaIterator {
  yield put(searchProductsSuccess(payload));
}

function* getProductAsync({ payload }: Action<{ id: string | number, storeSlug: string }>):
  SagaIterator {
  try {
    const { id, storeSlug } = payload;
    const response = yield call(getProduct, storeSlug, id);
    yield put(getProductSuccess(response.product));
  } catch (e) {
    yield put(getProductFailure(e));
  }
}

export default function* watchProductsActions(): SagaIterator {
  yield takeEvery(getProductRequest.toString(), getProductAsync);
  yield takeEvery(listProductsRequest.toString(), listProductsAsync);
  yield takeEvery(listLinkedProductsRequest.toString(), listLinkedProductsAsync);
  yield takeEvery(listExchangeProductsRequest.toString(), listExchangeProductsAsync);
  yield takeEvery(listCampaignProductsRequest.toString(), listCampaignProductsAsync);
  yield takeEvery(searchProductsRequest.toString(), searchProductsAsync);
}
