import firebase from 'firebase/app';
import get from 'lodash/get';
import padEnd from 'lodash/padEnd';
import trim from 'lodash/trim';
import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest, call, put, select, take, delay, race,
} from 'redux-saga/effects';

import {
  createAddress,
  deleteAddress,
  getAddressByCEP,
  getAddressByLocation,
  getLocationByAddress,
  updateAddress,
} from 'services/api/address';
import { setAuthToken } from 'services/api/http';
import {
  login, registerNewUser, getProfile, refreshToken, updateProfile, changePassword, recoverPassword, fetchNotificationToken,
  getExchangeBalance,
} from 'services/api/user';

import 'firebase/messaging';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
  getAddressByCepRequest,
  getAddressByCepSuccess,
  getAddressByCepFailure,
  logout,

  getAddressByLocationRequest,
  getAddressByLocationSuccess,
  getAddressByLocationFailure,
  createAddressRequest,
  createAddressFailure,
  createAddressSuccess,
  loadAuthDataRequest,
  loadAuthDataSuccess,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  getRefreshToken,
  getTemporaryAddress,
  clearCart,
  listStoresRequest,
  isLogged, syncCartRequest,
} from 'store/ducks';
import {
  updateAddressRequest,
  updateAddressFailure,
  updateAddressSuccess,
  recoverPasswordRequest,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  deleteAddressRequest,
  deleteAddressSuccess,
  deleteAddressFailure,
  setNotificationsSituation,
  checkNotificationsEnabled,
  registerDeviceToken,
} from 'store/ducks/user';

import { Address, Profile } from 'typing/models';
import {
  AddressLocationResponseBody,
  AddressResponseBody,
  LoginDataRequestBody,
  RegisterUserRequestBody,
  UpdateUserRequestBody,
} from 'typing/request';
import { LoginData } from 'typing/store';
import { GeoPosition } from 'typing/utils';

import { onlyNumber } from 'utils/masks';

function* updateUserRequestAsync({ payload }: Action<UpdateUserRequestBody>): SagaIterator {
  try {
    const { phone_number, user: { first_name, last_name } } = yield call(updateProfile, payload);
    yield put(updateUserSuccess({ phone_number, first_name, last_name }));
  } catch (e) {
    yield put(updateUserFailure(e));
  }
}

function* registerNewUserAsync({ payload }: Action<RegisterUserRequestBody>): SagaIterator {
  try {
    payload.identifier_code = onlyNumber(payload.identifier_code);
    const response = yield call(registerNewUser, payload);
    const { identifier_code: username, password } = payload;
    yield put(loginRequest({ username, password }));
    yield take(loginSuccess.toString());
    yield put(registerSuccess(response.data));
  } catch (e) {
    yield put(registerFailure(e.response.data));
  }
}

function* loginAsync({ payload }: Action<LoginDataRequestBody & {rememberPassword: boolean}>):
  SagaIterator {
  try {
    const { rememberPassword, ...loginData } = payload;
    loginData.username = onlyNumber(loginData.username);
    const data = yield call(login, loginData);

    if (rememberPassword) {
      localStorage.setItem('@bodega-mix/rememberLogin', JSON.stringify(loginData));
    } else {
      localStorage.removeItem('@bodega-mix/rememberLogin');
    }

    setAuthToken(data.access);
    localStorage.setItem('@bodega-mix/authData', JSON.stringify(data));

    try {
      const temporaryAddress = yield select(getTemporaryAddress);

      if (temporaryAddress) {
        const street2 = temporaryAddress?.street2.length ? temporaryAddress?.street2 : 'sem complemento';
        const address = yield call(createAddress, { ...temporaryAddress, street2 });
        localStorage.setItem('@bodega-mix/defaultAddress', JSON.stringify(address));
        yield put(getAddressByCepSuccess());
      }
    } catch (e) {
      yield put(getAddressByCepFailure(e));
    }

    const profile = yield call(getProfile);
    let balance = null;
    try {
      balance = yield call(getExchangeBalance);
    } catch { // ignore
    }
    yield put(loginSuccess({ tokens: data, profile, balance }));
    yield put(listStoresRequest());
    yield put(syncCartRequest());
  } catch (e) {
    yield put(loginFailure(e));
  }
}

function* loadAuthDataAsync(): SagaIterator {
  try {
    const data = JSON.parse(localStorage.getItem('@bodega-mix/authData') || 'null');

    if (data) {
      yield put(refreshTokenSuccess(data));
      yield put(refreshTokenRequest({ refreshToken: data.refresh }));

      const { success } = yield race({
        success: take(refreshTokenSuccess.toString()),
        failure: take(refreshTokenFailure.toString()),
      });

      if (success) {
        const profile = yield call(getProfile);
        let balance = null;
        try {
          balance = yield call(getExchangeBalance);
        } catch { // ignore
        }

        yield put(loginSuccess({ tokens: data, profile, balance }));
      } else {
        yield put(loginFailure());
      }
    }
  } catch (e) {
    yield put(loginFailure(e));
  }

  yield put(loadAuthDataSuccess());
}

function* refreshTokenAsync({ payload }:
                              Action<{refreshToken: string | null} | undefined>): SagaIterator {
  try {
    let refresh = yield select(getRefreshToken);

    if (!refresh) {
      refresh = payload?.refreshToken;
    }

    const { access } = yield call(refreshToken, refresh);

    setAuthToken(access);

    localStorage.setItem('@bodega-mix/authData', JSON.stringify({ refresh, access }));
    yield put(refreshTokenSuccess({ refresh, access }));
  } catch (e) {
    yield put(logout());
    yield put(refreshTokenFailure(e));

    // TODO: adicionado para remover bug de congelar a tela
    // tratar corretamente em seguida
    // yield put(refreshTokenSuccess({}));
  }
}

function* updateTokenAsync(): SagaIterator {
  while (true) {
    const refresh = yield select(getRefreshToken);

    if (refresh) {
      yield delay(4 * 60 * 1000);
      yield put(refreshTokenRequest({ refreshToken: refresh }));
    } else {
      break;
    }
  }
}

function* getAddressByCepAsync({ payload }: Action<string>): SagaIterator {
  try {
    const data: AddressResponseBody = yield call(getAddressByCEP, payload);

    const temporaryAddress = {
      street: data.logradouro,
      street2: '',
      district: data.bairro,
      city: data.localidade,
      state: data.uf,
      postal_code: data.cep,
      default: false,
    };

    yield put(getAddressByCepSuccess(temporaryAddress));
  } catch (e) {
    yield put(getAddressByCepFailure(e));
  }
}

function* getAddressByLocationAsync({ payload }: Action<GeoPosition>): SagaIterator {
  try {
    const { latitude, longitude } = payload;
    const { address }:
      AddressLocationResponseBody = yield call(getAddressByLocation, latitude, longitude);

    const street = trim(address.Address.replace(address.AddNum, ''));

    yield put(getAddressByLocationSuccess({
      street,
      street2: '',
      district: address.District,
      city: address.City,
      state: address.Region,
      postal_code: padEnd(address.Postal, 9, '-000'),
      default: false,
    }));
  } catch (e) {
    yield put(getAddressByLocationFailure(e));
  }
}

function* createAddressAsync({ payload }: Action<Address>): SagaIterator {
  try {
    const authorized = yield select(isLogged);

    if (authorized) {
      const street2 = payload?.street2?.length ? payload?.street2 : 'sem complemento';
      const data = yield call(createAddress, { ...payload, street2 });
      localStorage.setItem('@bodega-mix/defaultAddress', JSON.stringify(data));
      yield put(createAddressSuccess(data));
    } else {
      const addressLine = `${payload.street}, ${payload.street_number}, ${payload.district}, ${payload.city}, ${payload.state}`;
      const location: any = yield call(getLocationByAddress, addressLine);
      const { y: latitude, x: longitude } = get(location, '0.location', {});

      const temporaryAddress = { ...payload, latitude, longitude };

      yield put(createAddressSuccess(temporaryAddress));
      localStorage.setItem('@bodega-mix/defaultAddress', JSON.stringify(temporaryAddress));
      yield put(getAddressByCepSuccess(temporaryAddress));
    }

    yield put(listStoresRequest());
  } catch (e) {
    yield put(createAddressFailure(e));
  }
}

function* updateAddressAsync({ payload }: Action<Address>): SagaIterator {
  try {
    const data = yield call(updateAddress, payload);
    localStorage.setItem('@bodega-mix/defaultAddress', JSON.stringify(data));
    yield put(updateAddressSuccess(data));
  } catch (e) {
    yield put(updateAddressFailure(e));
  }
}

function* deleteAddressAsync({ payload }: Action<Address>): SagaIterator {
  try {
    yield call(deleteAddress, payload);

    const profile = yield call(getProfile);

    if (payload.default) {
      localStorage.setItem(
        '@bodega-mix/defaultAddress',
        JSON.stringify(profile.client.client_address.find(((address: Address) => address.default))),
      );
    }
    yield put(deleteAddressSuccess(profile.client.client_address));
  } catch (e) {
    yield put(deleteAddressFailure(e));
  }
}

function* resetPasswordAsync({ payload }:
                               Action<{ password: string, password2: string }>): SagaIterator {
  try {
    const data = yield call(changePassword, payload);
    yield put(resetPasswordSuccess(data));
  } catch (e) {
    yield put(resetPasswordFailure(e));
  }
}

function* recoverPasswordAsync({ payload }: Action<{ email: string }>): SagaIterator {
  try {
    const data = yield call(recoverPassword, payload);
    yield put(recoverPasswordSuccess(data));
  } catch (e) {
    yield put(recoverPasswordFailure(e));
  }
}

function* logoutAsync(): SagaIterator {
  localStorage.removeItem('@bodega-mix/authData');
  localStorage.removeItem('@bodega-mix/cart');
  localStorage.removeItem('@budega-mix/rejected-notifications');
  localStorage.removeItem('@bodega-mix/defaultAddress');

  yield put(clearCart());
}

function* checkNotificationsEnabledAsync(
  { payload }: Action<{ profile: Profile, clean?: boolean}>,
): SagaIterator {
  const { profile, clean } = payload;
  localStorage.removeItem('@budega-mix/notification-first-ask');

  const accepted = yield call(fetchNotificationToken, profile);

  if (clean) {
    localStorage.removeItem('@budega-mix/rejected-notifications');
  }
  const rejectionExpiresAt = localStorage.getItem('@budega-mix/rejected-notifications');
  const rejectedNotifications = rejectionExpiresAt ? new Date(rejectionExpiresAt).getTime() < new Date().getTime() : true;

  if ((!accepted && rejectedNotifications) || accepted) {
    yield put(setNotificationsSituation(clean && !accepted ? false : accepted));
  }
}

function* registerDeviceTokenAsync({ payload }: Action<{ profile: Profile}>): SagaIterator {
  const { profile } = payload;
  const rejectionExpiresAt = localStorage.getItem('@budega-mix/rejected-notifications');
  const rejectedNotifications = rejectionExpiresAt ? new Date(rejectionExpiresAt).getTime() < new Date().getTime() : true;
  if (window.Notification && Notification.permission === 'granted' && rejectedNotifications) {
    yield call(fetchNotificationToken, profile);
  }
}

export default function* watchAuthActions(): SagaIterator {
  yield takeLatest(logout.toString(), logoutAsync);
  yield takeLatest(resetPasswordRequest.toString(), resetPasswordAsync);
  yield takeLatest(createAddressRequest.toString(), createAddressAsync);
  yield takeLatest(updateAddressRequest.toString(), updateAddressAsync);
  yield takeLatest(deleteAddressRequest.toString(), deleteAddressAsync);
  yield takeLatest(registerRequest.toString(), registerNewUserAsync);
  yield takeLatest(updateUserRequest.toString(), updateUserRequestAsync);
  yield takeLatest(loginRequest.toString(), loginAsync);
  yield takeLatest(loginSuccess.toString(), updateTokenAsync);
  yield takeLatest(loadAuthDataRequest.toString(), loadAuthDataAsync);
  yield takeLatest(refreshTokenRequest.toString(), refreshTokenAsync);
  yield takeLatest(recoverPasswordRequest.toString(), recoverPasswordAsync);
  yield takeLatest(getAddressByCepRequest.toString(), getAddressByCepAsync);
  yield takeLatest(getAddressByLocationRequest.toString(), getAddressByLocationAsync);
  yield takeLatest(checkNotificationsEnabled.toString(), checkNotificationsEnabledAsync);
  yield takeLatest(registerDeviceToken.toString(), registerDeviceTokenAsync);
}
