export const LOGIN = Symbol.for('LOGIN');
export const REGISTER = Symbol.for('REGISTER');
export const UPDATE_USER = Symbol.for('UPDATE_USER');
export const RESET_PASSWORD = Symbol.for('RESET_PASSWORD');
export const RECOVER_PASSWORD = Symbol.for('RECOVER_PASSWORD');
export const REFRESH_TOKEN = Symbol.for('REFRESH_TOKEN');
export const GET_ADDRESS_BY_CEP = Symbol.for('GET_ADDRESS_BY_CEP');
export const GET_ADDRESS_BY_LOCATION = Symbol.for('GET_ADDRESS_BY_LOCATION');
export const CREATE_ADDRESS = Symbol.for('CREATE_ADDRESS');
export const UPDATE_ADDRESS = Symbol.for('UPDATE_ADDRESS');
export const DELETE_ADDRESS = Symbol.for('DELETE_ADDRESS');
export const LOAD_AUTH_DATA = Symbol.for('LOAD_AUTH_DATA');
export const LOAD_PAYMENT_METHODS = Symbol.for('LOAD_PAYMENT_METHODS');
export const LOAD_SHIPPING_METHODS = Symbol.for('LOAD_SHIPPING_METHODS');
export const LOAD_SELF_SHIPPING_METHODS = Symbol.for('LOAD_SELF_SHIPPING_METHODS');
export const BUY_CART = Symbol.for('BUY_CART');
export const GET_CART_NOTIFICATIONS = Symbol.for('GET_CART_NOTIFICATIONS');
export const LIST_CATEGORIES = Symbol.for('LIST_CATEGORIES');
export const LIST_FEATURED_CATEGORIES = Symbol.for('LIST_FEATURED_CATEGORIES');
export const LIST_SIDE_MENU_CATEGORIES = Symbol.for('LIST_SIDE_MENU_CATEGORIES');
export const LIST_FRONT_VIEW_CATEGORIES = Symbol.for('LIST_FRONT_VIEW_CATEGORIES');
export const LIST_PRODUCTS = Symbol.for('LIST_PRODUCTS');
export const LIST_FILTERS_PRODUCT = Symbol.for('LIST_FILTERS_PRODUCT');
export const SEARCH_PRODUCTS = Symbol.for('SEARCH_PRODUCTS');
export const LIST_STORES = Symbol.for('LIST_STORES');
export const GET_STORE = Symbol.for('GET_STORE');
export const LIST_CAMPAIGNS = Symbol.for('LIST_CAMPAIGNS');
export const LIST_LINKS = Symbol.for('LIST_LINKS');
export const ADD_PRODUCT_TO_CART = Symbol.for('ADD_PRODUCT_TO_CART');
export const DOWNLOAD_CART = Symbol.for('DOWNLOAD_CART');
export const REMOVE_PRODUCT_TO_CART = Symbol.for('REMOVE_PRODUCT_TO_CART');
export const LOAD_CART = Symbol.for('LOAD_CART');
export const SYNC_CART = Symbol.for('SYNC_CART');
export const VERIFY_CART = Symbol.for('VERIFY_CART');
export const RESPONSE_CREDIT_CARD = Symbol.for('RESPONSE_CREDIT_CARD');
export const LIST_ORDERS = Symbol.for('LIST_ORDERS');
export const GET_ORDER_DETAIL = Symbol.for('GET_ORDER_DETAIL');
export const START_APP = Symbol.for('START_APP');
export const GET_PRODUCT = Symbol.for('GET_PRODUCT');
export const LIST_LINKED_PRODUCTS = Symbol.for('LIST_LINKED_PRODUCTS');
export const LIST_EXCHANGE_PRODUCTS = Symbol.for('LIST_EXCHANGE_PRODUCTS');
export const LIST_CAMPAIGN_PRODUCTS = Symbol.for('LIST_CAMPAIGN_PRODUCTS');
