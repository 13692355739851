import { AxiosPromise } from 'axios';

import { Profile } from 'typing/models';
import {
  ChangePasswordRequestBody,
  ExchangeBalanceResponseDataBody,
  LoginDataRequestBody,
  LoginResponseDataBody,
  RecoverPasswordRequestBody,
  RegisterUserRequestBody,
  UpdateUserRequestBody,
} from 'typing/request';

import { fetchToken } from '../../firebase';
import http from './http';

export const registerNewUser = (userData: RegisterUserRequestBody):
  AxiosPromise<RegisterUserRequestBody> => http.post(
  '/client/create/',
  userData,
);

export const login = (loginData: LoginDataRequestBody):
  AxiosPromise<LoginResponseDataBody> => http.post(
  '/auth/token/create/',
  loginData,
);

export const getProfile = ():
  AxiosPromise<RegisterUserRequestBody> => http.get('/auth/token/me/');

export const getExchangeBalance = ():
  AxiosPromise<ExchangeBalanceResponseDataBody> => http.get('/store/client/exchange-balance/');

export const updateProfile = (data: UpdateUserRequestBody):
  AxiosPromise<RegisterUserRequestBody> => http.put('/auth/token/me/', data);

export const changePassword = (data: ChangePasswordRequestBody):
  AxiosPromise<any> => http.post('/client/reset-password/', data);

export const recoverPassword = (data: RecoverPasswordRequestBody):
  AxiosPromise<any> => http.post('/client/recover-password/', data);

export const refreshToken = (refresh: string):
  AxiosPromise<RegisterUserRequestBody> => http.post('/auth/token/refresh/', { refresh });

export const fetchNotificationToken = (profile: Profile):
    Promise<boolean | undefined> => fetchToken(profile);
