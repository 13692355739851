import { ArrowRight } from '@styled-icons/fa-solid';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getCart, getLoginData, syncCartRequest,
} from 'store/ducks';
import { clearCartItems } from 'store/ducks/cart';
import { getRealExchangeBalance, hasActiveCampaign } from 'store/ducks/user';

import { numberToMoney } from 'utils/monetary';

import ModalDiscardCart from '../ModalDiscardCart/ModalDiscardCart';
import { Container, Description } from './ExchangeBanner.styled';

export default function ExchangeBanner(): React.ReactElement {
  const loginData = useSelector(getLoginData);
  const hasCampaign = useSelector(hasActiveCampaign);
  const { balance, expired } = useSelector(getRealExchangeBalance);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isInExchangeScreen = useMemo(() => location.pathname?.startsWith('/exchanges'), [location]);

  const cart = useSelector(getCart);

  const [showModal, setShowModal] = useState(false);

  const cleanCart = async () => {
    await dispatch(clearCartItems());
    await dispatch(syncCartRequest());
  };

  const handleAccept = useCallback(async (clean = true) => {
    history.push('/exchanges/');
    setShowModal(false);
    if (clean) {
      await cleanCart();
    }
  }, []);
  const handleReject = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleNavigate = useCallback(() => {
    const isExchangeCart = cart.items.every((item) => item.product.flag === 'exchange_item');
    if (!isExchangeCart && cart.items.length > 0) {
      setShowModal(true);
      return;
    }
    handleAccept(false);
  }, [cart]);

  useEffect(() => {
    if (isInExchangeScreen) {
      handleNavigate();
    }
  }, [isInExchangeScreen, handleNavigate]);

  useEffect(() => {
    const isExchangeCart = cart.items.every((item) => item.product.flag === 'exchange_item');
    if (isExchangeCart && !hasCampaign && cart.items.length > 0) {
      cleanCart();
    }
  }, [hasCampaign, cart.items]);

  if (!loginData) return <></>;

  if (!hasCampaign || expired) {
    return <></>;
  }
  return (

    <>
      <Container onClick={handleNavigate}>
        <Description>
          Você tem
          {' '}
          <strong>{numberToMoney(balance)}</strong>
          {' '}
          de saldo para trocas,
          {' '}
          <a>
            <ArrowRight
              size={16}
              style={{
                marginRight: 4,
              }}
            />
            clique aqui e veja os produtos disponíveis para troca.
          </a>
        </Description>
        <Description>Lembre-se que você deve fazer um carrinho exclusivo para troca.</Description>

      </Container>
      {showModal && (
      <ModalDiscardCart
        onReject={handleReject}
        onAccept={handleAccept}
        disableReject={isInExchangeScreen}
      />
      )}
    </>
  );
}
