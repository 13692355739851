import produce from 'immer';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import { createActions, handleActions } from 'redux-actions';

import { Product as ProductType, Product } from 'typing/models';
import {
  AbstractPayload, BaseState, ProductsFilter, ProductsState,
} from 'typing/store';

import {
  LIST_PRODUCTS,
  SEARCH_PRODUCTS,
  GET_PRODUCT,
  LIST_LINKED_PRODUCTS,
  LIST_CAMPAIGN_PRODUCTS,
  LIST_EXCHANGE_PRODUCTS,
} from 'utils/fetchs';

import { fetchStateGenerator, fetchStateWithMetaGenerator } from './checkFetchReducerCreator';

export const {
  listProductsRequest,
  listProductsSuccess,
  listProductsFailure,
  listProductsClean,
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFailure,
  searchProductsClean,
  getProductRequest,
  getProductSuccess,
  getProductFailure,
  getProductClean,
  listLinkedProductsRequest,
  listLinkedProductsSuccess,
  listLinkedProductsFailure,
  listLinkedProductsClean,
  listCampaignProductsRequest,
  listCampaignProductsSuccess,
  listCampaignProductsFailure,
  listCampaignProductsClean,
  listExchangeProductsRequest,
  listExchangeProductsSuccess,
  listExchangeProductsFailure,
  listExchangeProductsClean,
  searchProducts,
  selectProduct,
  unselectProduct,
} = createActions(
  fetchStateWithMetaGenerator(LIST_PRODUCTS),
  ...fetchStateGenerator(SEARCH_PRODUCTS),
  ...fetchStateGenerator(GET_PRODUCT),
  ...fetchStateGenerator(LIST_LINKED_PRODUCTS),
  ...fetchStateGenerator(LIST_EXCHANGE_PRODUCTS),
  ...fetchStateGenerator(LIST_CAMPAIGN_PRODUCTS),
  'SELECT_PRODUCT',
  'UNSELECT_PRODUCT',
  { prefix: 'PRODUCT' },
);

const INITIAL_STATE = {
  mapped: {
    search: {
      list: {
        data: [],
      },
    },
    home: {
      list: {
        data: [],
      },
    },
    link: {
      list: {
        data: [],
      },
    },
    campaign: {
      list: {
        data: [],
      },
    },
    exchange: {
      list: {
        data: [],
      },
    },
  },
  detail: null,
};

const filterProducts = (products: Product[], categoryId: number | null = null):
  Product[] => {
  if (categoryId) {
    return products.filter(
      (product) => {
        const categoryIds = product.category_ids.map((category) => category.id);
        const parentCategoryIds = product.category_ids.map((category) => category.parent_id);

        return categoryIds.includes(categoryId) || parentCategoryIds.includes(categoryId);
      },
    );
  }

  return products;
};

export const getSelectedProduct = (state: BaseState): Product | null => state.products.detail;
export const getLinkedProducts = (
  storeSlug: string, categoryId: number | null = null,
) => (state: BaseState): Product[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return filterProducts(state.products.mapped.link.list.data, categoryId);
  }

  return [];
};

export const getExchangeProducts = (
  storeSlug: string, categoryId: number | null = null,
) => (state: BaseState): Product[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return filterProducts(state.products.mapped.exchange.list.data, categoryId);
  }

  return [];
};

export const getCampaignProducts = (
  storeSlug: string, categoryId: number | null = null,
) => (state: BaseState): Product[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return filterProducts(state.products.mapped.campaign.list.data, categoryId);
  }

  return [];
};

export const getFieldForExtraData = (
  listName: string, field = 'title',
) => (state: BaseState):
  string | undefined => get(state.products.mapped[listName], `list.extraData.${field}`);

export const getProducts = (
  storeSlug: string, categoryId: number, limit?: number,
) => (state: BaseState): Product[] => {
  if (state.stores.detail?.slug === storeSlug && categoryId) {
    const products = get(state.products.mapped[categoryId.toString()], 'list.data', []);
    if (!limit) {
      return products;
    }

    return products.slice(0, limit);
  }

  return [];
};

export const getProductById = (
  productId: string | number,
) => (state: BaseState): Product | undefined => {
  const productsArr: any = Object.keys(state.products.mapped).map(
    (key) => get(state.products.mapped, `${key}.list.data`, []),
  );

  const products: Product[] = flatten(productsArr);

  return products.find((product) => product.id === productId);
};

export const getMappedProducts = (state: BaseState): any => {
  const { search, ...mapped } = state.products.mapped;

  return mapped;
};

export const getItemUnitValue = (product: Product):
  number => product.product_child.promo_price ?? product.product_child.price;

export const getSearchedProducts = (
  storeSlug: string, categoryId: number | null = null,
) => (state: BaseState): Product[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return filterProducts(state.products.mapped.search.list.data, categoryId);
  }

  return [];
};

export const getAllProducts = (
  storeSlug: string,
) => (state: BaseState): Product[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return state.products.mapped.home.list.data;
  }
  return [];
};

export const getProductsFilters = (categoryId: number | string) => (state: BaseState):
  ProductsFilter | undefined => (
  state.products.mapped[categoryId] ? state.products.mapped[categoryId].list.filter : undefined
);

const listProductsHandler = produce(
  (draft: ProductsState, {
    payload: {
      data, categoryId, search, append,
    },
  }) => {
    if (search) {
      draft.mapped.search.list.data = data;
    } else if (!categoryId) {
      if (append) {
        draft.mapped.home.list.data = [
          ...draft.mapped.home.list.data.map((item) => item), ...data,
        ];
      } else {
        draft.mapped.home = { list: { data } };
      }
    } else if (append && !!draft.mapped[categoryId]?.list?.data) {
      draft.mapped[categoryId].list.data = [
        ...draft.mapped[categoryId].list.data.map((item) => item), ...data,
      ];
    } else {
      draft.mapped[categoryId] = { list: { data } };
    }
  },
);

export default handleActions<ProductsState, AbstractPayload>({
  [listProductsSuccess.toString()]: listProductsHandler,
  [listProductsFailure.toString()]: listProductsHandler,
  [getProductSuccess.toString()]: produce((draft: ProductsState, { payload }) => {
    draft.detail = payload;
  }),
  [selectProduct.toString()]: produce((draft: ProductsState, { payload }) => {
    draft.detail = payload;
  }),
  [unselectProduct.toString()]: produce((draft: ProductsState) => {
    draft.detail = null;
  }),
  [searchProductsSuccess.toString()]: produce((draft: ProductsState, { payload }) => {
    if (draft.mapped.search.list.filter) {
      draft.mapped.search.list.filter.searchName = payload?.searchName;
    } else {
      draft.mapped.search.list.filter = {
        extra_filters: {},
        searchName: payload?.searchName,
      };
    }
  }),
  [listLinkedProductsSuccess.toString()]: produce((draft: ProductsState, { payload }) => {
    const { items, ...extra } = payload;
    draft.mapped.link.list.data = items.map((item: { product: Product }) => item.product);
    draft.mapped.link.list.extraData = extra;
  }),
  [listExchangeProductsSuccess.toString()]: produce((draft: ProductsState, { payload }) => {
    const { data } = payload;
    draft.mapped.exchange.list.data = data as Product[];
    draft.mapped.exchange.list.extraData = {
      fetched: true,
    };
  }),
  [listCampaignProductsSuccess.toString()]: produce((draft: ProductsState, { payload }) => {
    const { items, ...extra } = payload;

    const flatItems = items.map((item: { product: Product }) => item.product).filter((item) => 'name' in item);

    const listItems = items.map((item: { products: Product[] }) => item.products).flatMap((item) => item);

    draft.mapped.campaign.list.data = listItems.concat(flatItems);
    draft.mapped.campaign.list.extraData = extra;
  }),
  [searchProductsClean.toString()]: produce((draft: ProductsState) => {
    draft.mapped.search.list.filter = undefined;
  }),
  [listLinkedProductsClean.toString()]: produce((draft: ProductsState) => {
    draft.mapped.link = {
      list: {
        data: [],
      },
    };
  }),
  [listCampaignProductsClean.toString()]: produce((draft: ProductsState) => {
    draft.mapped.campaign = {
      list: {
        data: [],
      },
    };
  }),
  [listProductsClean.toString()]: produce((draft: ProductsState, { payload }) => {
    if (payload !== undefined) {
      if (draft.mapped[payload.toString()]) {
        draft.mapped[payload.toString()].list.data = [];
      }
    } else {
      draft.mapped.home.list.data = [];
    }
  }),
  [listExchangeProductsClean.toString()]: produce((draft: ProductsState) => {
    draft.mapped.exchange = {
      list: {
        data: [],
      },
    };
  }),
}, INITIAL_STATE);
