import { Filter } from '@styled-icons/feather';
import get from 'lodash/get';
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useSearchParams from 'hooks/useSearchParams';

import {
  getProductsFilters, getSelectedStore, isLoading,
} from 'store/ducks';
import { getConfig, isSingleStore } from 'store/ducks/base';
import {
  getCampaignProducts,
  getExchangeProducts,
  getLinkedProducts,
  getProducts,
  getSearchedProducts,
  listExchangeProductsRequest,
  listLinkedProductsRequest,
  listProductsRequest,
  selectProduct,
} from 'store/ducks/products';

import { Product } from 'typing/models';

import { SEARCH_PRODUCTS_EMPTY_LIST_NOTICE } from 'utils/contants';
import { LIST_PRODUCTS } from 'utils/fetchs';

import SettingsContext from '../../../context/SettingsContext';
import ProductCard from '../ProductCard';
import ProductDetail from '../ProductDetail';
import { Col, Row } from '../ProductDetail/ProductDetail.styled';
import {
  Heading, SectionHeaderContainer, Title, ViewAll,
} from '../SectionHeader/SectionHeader.styled';
import {
  Button,
  ButtonDisabled,
  ButtonFilter,
  NoResult,
  ProductSlider,
  ViewMoreContainer,
} from './ProductsFull.styled';

interface ProductsSliderProps {
    categoryId: number;
    categoryName?: string;
    showFilter?: boolean;
    exchange?: boolean;
}

export default function ProductsFull({
  categoryId,
  categoryName,
  showFilter = true,
  exchange = false,
}: ProductsSliderProps): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const { openModal } = useContext(SettingsContext);
  const elemRef = useRef<any>(null);
  const { campaignId, linkId } = useParams<{
    campaignId?: string, linkId?: string
  }>();
  const [childId, categoryIdParam] = useSearchParams(['childId', 'categoryId']);
  const productsFilter = useSelector(getProductsFilters('search'));
  const store = useSelector(getSelectedStore);
  const singleStore = useSelector(isSingleStore);

  const { search_products_empty_list_notice = SEARCH_PRODUCTS_EMPTY_LIST_NOTICE } = get(
    useSelector(getConfig),
    'config.labels',
  );

  const [page, setPage] = useState(0);

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const fetchId = useMemo(() => `${categoryId}_${page}_filters`, [categoryId, page]);

  const storeSlug = useMemo(() => store?.slug || 'undefined', []);

  const loading = useSelector(isLoading(LIST_PRODUCTS, fetchId));

  const listProducts = () => {
    if (categoryIdParam) {
      return getProducts(storeSlug, categoryId);
    }

    if (linkId) {
      return getLinkedProducts(storeSlug);
    }

    if (campaignId) {
      return getCampaignProducts(storeSlug, categoryId);
    }
    if (exchange) {
      return getExchangeProducts(storeSlug);
    }
    return getSearchedProducts(storeSlug, categoryId);
  };

  const products = useSelector(listProducts());

  useEffect(() => {
    if (childId && childId.toString() === categoryId) {
      setTimeout(() => {
        elemRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [elemRef.current, childId]);

  useEffect(() => {
    // Problem: Eventually on user go back to products list,
    // the page is 0 and but the products list is not empty

    // Solution: If the products list is not empty and the page is 0,
    // do not make a request and set the page to hypotheticPage, but what is the hypotheticPage?
    // The hypotheticPage is the calculated page based on the products list length
    // and the products per page witch is 20, so if the products list length is 40,
    // the hypotheticPage is 1.

    const hypotheticPage = Math.floor(products.length / 20) - 1 || 0;
    if (page === hypotheticPage || (products.length > 0 && page === 0)) {
      setPage(hypotheticPage);
      return;
    }
    if (!exchange) dispatch(listProductsRequest(categoryId, fetchId, page));
    if (page) {
      if (linkId) {
        dispatch(listLinkedProductsRequest([linkId, page]));
      } else if (exchange) {
        dispatch(listExchangeProductsRequest([page]));
      }
    }
  }, [page]);

  const nextPage = useMemo(() => {
    if ((page + 1) * 20 > products.length || !!productsFilter?.searchName) {
      return null;
    }
    return page + 1;
  }, [page, products]);

  const selectCurrentProduct = (product: Product): void => {
    if (product.has_variants) {
      dispatch(selectProduct(product));
      if (singleStore) {
        history.push(`/product/${product.id}/`, {
          exchange,
        });
      } else {
        history.push(`/store/${storeSlug}/product/${product.id}/`, {
          exchange,
        });
      }
    } else {
      setSelectedProduct(product);
    }
  };

  return (
    <ProductSlider ref={elemRef}>
      <SectionHeaderContainer>
        {categoryName ? (
          <Heading>
            <Title>{categoryName}</Title>
          </Heading>
        ) : null}
        {showFilter && (
        <ViewAll>
          <ButtonFilter onClick={(): void => openModal && openModal('MODAL_FILTERS_PRODUCT')}>
            <Filter width={20} height={20} />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              Filtros
            </span>
          </ButtonFilter>
        </ViewAll>
        )}
      </SectionHeaderContainer>

      <Row className="row">
        {products.map((product) => (
          <Col
            className="col-6 col-sm-6 col-lg-3 col-md-6 mb-4 mb-md-0"
            key={product.id}
            style={{
              display: 'flex',
            }}
          >
            <ProductCard
              key={product.id}
              product={product}
              selectProduct={(): void => selectCurrentProduct(product)}
              modifiers
              exchangeFlag={exchange}
            />
          </Col>
        ))}

        {!products.length && !loading && <NoResult>{search_products_empty_list_notice}</NoResult> }
      </Row>

      {loading ? (
        <ViewMoreContainer>
          <ButtonDisabled>Carregando...</ButtonDisabled>
        </ViewMoreContainer>
      ) : (
        Boolean(showFilter && nextPage) && (
        <ViewMoreContainer>
          <Button
            onClick={(): void => {
              setPage(page + 1);
            }}
          >
            Ver mais
          </Button>
        </ViewMoreContainer>
        )
      )}

      <ProductDetail product={selectedProduct} unselectProduct={(): void => setSelectedProduct(null)} />
    </ProductSlider>
  );
}
