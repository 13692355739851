import { ShoppingBag, ChevronLeft } from '@styled-icons/feather';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import Logo from 'assets/images/logo_dark.svg';

import SettingsContext, { REGULAR, AUTH, REGISTER } from 'context/SettingsContext';

import {
  getAddressByCepSuccess, getCart, getCartSubTotal, getLoginData, isLogged,
} from 'store/ducks';
import { getHideCartAddress, getConfig } from 'store/ducks/base';
import { hasCartNotifications } from 'store/ducks/cart';
import { getPendingOrdersQty } from 'store/ducks/orders';
import { getDefaultAddress } from 'store/ducks/user';

import ProfileMenu from 'ui/components/ProfileMenu';

import { numberToMoney } from 'utils/monetary';

import ExchangeBanner from '../ExchangeBanner';
import ModalAddress from '../ModalAddress';
import {
  AppNavHeaderContainer,
  MainMenu,
  Container,
  NavBar,
  NavBrand,
  Brand,
  Address,
  NavBarNav,
  NavBarList,
  Item,
  ItemDropdown,
  DropdownMenu,
  NavLink,
  CartButton,
  Collapse,
  CartCount,
  CartAmount,
  AddressButton,
  BackButton,
} from './AppNavHeader.styled';

export default function AppNavHeader(): React.ReactElement {
  const { openModal, navType, isVisibleModal } = useContext(SettingsContext);
  const dispatch = useDispatch();
  const lastLocation = useLastLocation();

  const ordersQty = useSelector(getPendingOrdersQty);

  const hideCartAddress = useSelector(getHideCartAddress);

  const modalAddress: boolean = !!isVisibleModal && isVisibleModal('ADDRESS');

  const history = useHistory();

  const homeRootPath = useRouteMatch('/');
  const ordersRootPath = useRouteMatch('/orders');
  const profileRootPath = useRouteMatch('/profile');

  const isRootPath = homeRootPath?.isExact || ordersRootPath?.isExact || profileRootPath?.isExact;

  const goBack = useCallback(() => {
    const locationArr = history.location.pathname.split('/');

    const [, rootPath] = locationArr;

    if ((!isRootPath && !lastLocation) || (locationArr.length === 3 && rootPath === 'store')) {
      locationArr.pop();
      history.replace(locationArr.join('/'));
    } else {
      history.goBack();
    }
  }, [isRootPath, lastLocation, history]);

  const address = useSelector(getDefaultAddress);
  const authorized = useSelector(isLogged);

  const alert = useSelector(hasCartNotifications);

  const cart = useSelector(getCart);
  const subTotal = useSelector(getCartSubTotal);

  const profile = useSelector(getLoginData);

  const { image_logo: logo } = useSelector(getConfig);

  useEffect(() => {
    const defaultAddress = JSON.parse(localStorage.getItem('@bodega-mix/defaultAddress') || 'null');
    dispatch(getAddressByCepSuccess(defaultAddress));
  }, [dispatch, authorized]);

  return (
    <AppNavHeaderContainer>
      <ExchangeBanner />
      <MainMenu>
        <Container>
          <NavBar>
            <NavBrand>
              <NavLink to="/">
                <Brand src={logo || ''} />
              </NavLink>
            </NavBrand>
            {!isRootPath && (
            <BackButton onClick={goBack}>
              <ChevronLeft />
            </BackButton>
            )}
            {navType === REGULAR && (
            <>
              {!hideCartAddress && !!address && (
              <Address>
                <NavBarNav>
                  <Item>
                    <AddressButton onClick={(): void => openModal && openModal('ADDRESS')}>
                      <strong>Entregar em:</strong>
                      {' '}
                      <span>{address.street}</span>
                      {', '}
                      {address.street_number}
                      {' ›'}
                    </AddressButton>
                  </Item>
                </NavBarNav>
              </Address>
              )}
              <Collapse>
                <NavBarNav>
                  {!authorized ? (
                    <Item>
                      <NavLink to={`/profile/login?next=${history.location.pathname}`}>
                        <span>Entrar</span>
                      </NavLink>
                    </Item>
                  ) : (
                    <>
                      <Item>
                        <NavLink to="/orders">
                          <span>Pedidos</span>
                          {ordersQty > 0 && <CartCount>{ordersQty}</CartCount>}
                        </NavLink>
                      </Item>
                      <ItemDropdown>
                        <NavLink to="/profile/login">
                          <span>
                            Olá,
                            {' '}
                            {profile?.profile.first_name}
                            !
                          </span>
                        </NavLink>
                        <DropdownMenu>
                          <ProfileMenu />
                        </DropdownMenu>
                      </ItemDropdown>
                    </>
                  )}
                </NavBarNav>
              </Collapse>
              <NavBarList>
                <Item>
                  <CartButton onClick={(): void => openModal && openModal('CART')}>
                    <ShoppingBag />
                    {!!cart?.items.length && (
                    <>
                      <CartCount modifiers={alert ? ['Alert'] : []}>
                        {cart.items.length}
                      </CartCount>
                      <CartAmount>{numberToMoney(subTotal)}</CartAmount>
                    </>
                    )}
                  </CartButton>
                </Item>
              </NavBarList>
            </>
            )}
            {navType === AUTH && (
            <Collapse>
              <NavBarNav>
                <Item>
                  <NavLink to="/profile/register/">
                    <span>Cadastre-se</span>
                  </NavLink>
                </Item>
              </NavBarNav>
            </Collapse>
            )}
            {navType === REGISTER && (
            <Collapse>
              <NavBarNav>
                <Item>
                  <NavLink to={`/profile/login?next=${history.location.pathname}`}>
                    <span>Entrar</span>
                  </NavLink>
                </Item>
              </NavBarNav>
            </Collapse>
            )}
          </NavBar>
        </Container>
      </MainMenu>
      {modalAddress && <ModalAddress />}
    </AppNavHeaderContainer>
  );
}
