import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { getLoginData } from 'store/ducks';
import { getRealExchangeBalance, hasActiveCampaign } from 'store/ducks/user';

import { numberToMoney } from 'utils/monetary';

import {
  Container, Title, Currency, Description,
} from './ExchangeBalance.styled';

export default function ExchangeBalance(): React.ReactElement {
  const loginData = useSelector(getLoginData);
  const hasCampaign = useSelector(hasActiveCampaign);
  const { balance, due, expired } = useSelector(getRealExchangeBalance);

  if (!loginData) return <></>;

  if (!hasCampaign) {
    return <></>;
  }

  const formattedDue = due ? moment(due).format('DD [de] MMMM [de] YYYY') : '--:--:---';

  return (
    <Container>
      <Title>Saldo disponível para trocas</Title>
      <Currency>
        {numberToMoney(balance)}
      </Currency>
      <Description variant={expired ? 'danger' : undefined}>
        {expired ? 'Seu saldo expirou em' : 'Seu saldo expira em'}
        {' '}
        {formattedDue}
      </Description>

    </Container>
  );
}
