import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import useScrollReset from 'hooks/useScrollReset';

import { isSingleStore } from 'store/ducks/base';

import { Home, Store, ProductDetail } from 'ui/screens';
import StoreCategory from 'ui/screens/StoreCategory/StoreCategory';
import StoreLinks from 'ui/screens/StoreLinks';

import ShoppingCartRouter from './ShoppingCartRouter';

export default function HomeRouter(): React.ReactElement {
  useScrollReset();

  const singleStore = useSelector(isSingleStore);

  return (
    <Switch>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/product/:productId`} exact>
        <ProductDetail />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/links`} exact>
        <StoreLinks />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/link/:linkId`} exact>
        <StoreCategory />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/link/:linkId/product/:productId`} exact>
        <ProductDetail />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/exchanges`} exact>
        <StoreCategory exchanges />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/campaign/:campaignId`} exact>
        <StoreCategory />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/search`} exact>
        <StoreCategory />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}/cart`}>
        <ShoppingCartRouter />
      </Route>
      <Route path={`${!singleStore ? '/store/:storeSlug' : ''}`} exact>
        <Store />
      </Route>
      <Route path="/store/" exact>
        <Redirect to="/" />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
