import produce from 'immer';
import first from 'lodash/first';
import { createActions, handleActions } from 'redux-actions';

import { Address, ExchangeBalance } from 'typing/models';
import {
  AbstractPayload, UserState, BaseState, LoginData,
} from 'typing/store';

import {
  CREATE_ADDRESS,
  GET_ADDRESS_BY_CEP,
  GET_ADDRESS_BY_LOCATION,
  LOGIN,
  REFRESH_TOKEN,
  REGISTER,
  UPDATE_USER,
  LOAD_AUTH_DATA,
  RESET_PASSWORD, UPDATE_ADDRESS, RECOVER_PASSWORD, DELETE_ADDRESS,
} from 'utils/fetchs';

import { fetchStateGenerator } from './checkFetchReducerCreator';

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  loginClean,
  registerRequest,
  registerSuccess,
  registerFailure,
  registerClean,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  updateUserClean,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  resetPasswordClean,
  recoverPasswordRequest,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  recoverPasswordClean,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
  refreshTokenClean,
  getAddressByCepRequest,
  getAddressByCepSuccess,
  getAddressByCepFailure,
  getAddressByCepClean,
  getAddressByLocationRequest,
  getAddressByLocationSuccess,
  getAddressByLocationFailure,
  getAddressByLocationClean,
  createAddressRequest,
  createAddressSuccess,
  createAddressFailure,
  createAddressClean,
  updateAddressRequest,
  updateAddressSuccess,
  updateAddressFailure,
  updateAddressClean,
  deleteAddressRequest,
  deleteAddressSuccess,
  deleteAddressFailure,
  deleteAddressClean,
  loadAuthDataRequest,
  loadAuthDataSuccess,
  loadAuthDataFailure,
  loadAuthDataClean,
  logout,
  loadAuthData,
  setNotificationsSituation,
  checkNotificationsEnabled,
  registerDeviceToken,
} = createActions(
  {},
  ...fetchStateGenerator(LOGIN),
  ...fetchStateGenerator(REGISTER),
  ...fetchStateGenerator(UPDATE_USER),
  ...fetchStateGenerator(RESET_PASSWORD),
  ...fetchStateGenerator(RECOVER_PASSWORD),
  ...fetchStateGenerator(REFRESH_TOKEN),
  ...fetchStateGenerator(GET_ADDRESS_BY_CEP),
  ...fetchStateGenerator(GET_ADDRESS_BY_LOCATION),
  ...fetchStateGenerator(CREATE_ADDRESS),
  ...fetchStateGenerator(UPDATE_ADDRESS),
  ...fetchStateGenerator(DELETE_ADDRESS),
  ...fetchStateGenerator(LOAD_AUTH_DATA),
  'LOGOUT',
  'CHECK_NOTIFICATIONS_ENABLED',
  'REGISTER_DEVICE_TOKEN',
  'SET_NOTIFICATIONS_SITUATION',
  { prefix: 'USER' },
);

const INITIAL_STATE = {
  loginData: null,
  temporaryAddress: null,
  isLogged: false,
  registerErrors: null,
  genericError: null,
  notificationsAccepted: undefined,
  temporarySuccess: null,
};

export const getLoginData = (state: BaseState): LoginData | null => state.auth.loginData;

export const hasActiveCampaign = (state: BaseState): boolean => {
  const { balance } = state.auth.loginData ?? {};
  const exchangeBalance = balance;
  if (!exchangeBalance) return false;
  const realBalance = exchangeBalance.saldo - exchangeBalance.saldo_retido;

  return exchangeBalance.campanha_ativa && realBalance > 0;
};

export const getRealExchangeBalance = (state: BaseState): {
  balance: number;
  due?: Date,
  expired: boolean
} => {
  const { balance } = state.auth.loginData ?? {};
  const exchangeBalance = balance;
  if (!exchangeBalance) {
    return {
      balance: 0,
      expired: true,
    };
  }
  const realBalance = exchangeBalance.saldo - exchangeBalance.saldo_retido;

  return {
    balance: realBalance,
    due: exchangeBalance.vencimento,
    expired: exchangeBalance.vencimento ? Date.now() > new Date(exchangeBalance.vencimento).getTime() : true,
  };
};

export const getExchangeBalance = (state: BaseState): ExchangeBalance | null | undefined => state.auth.loginData?.balance;
export const isLogged = (state: BaseState): boolean => state.auth.isLogged;
export const getAddresses = (state: BaseState):
  Address[] | undefined => state.auth.loginData?.profile.client.client_address;
export const getRegisterErrors = (state: BaseState): any | null => state.auth.registerErrors;
export const getGenericError = (state: BaseState): any | null => state.auth.genericError;
export const getTemporarySuccess = (state: BaseState): any | null => state.auth.temporarySuccess;

export const getDefaultAddress = ({ auth }: BaseState):
  Address | null | undefined => {
  if (auth.isLogged) {
    const addresses = auth.loginData?.profile.client.client_address;
    const defaultAddress = addresses?.find((addr) => addr.default);
    return defaultAddress || first(addresses);
  }
  if (auth.temporaryAddress) {
    return auth.temporaryAddress;
  }
  return null;
};

export const getTemporaryAddress = ({ auth }: BaseState):
  Address | null | undefined => auth.temporaryAddress;

export const getRefreshToken = (state: BaseState):
  string | undefined => state.auth.loginData?.tokens.refresh;
export const getAccessToken = (state: BaseState):
  string | undefined => state.auth.loginData?.tokens.access;

export const getNotificationsAccepted = (state: BaseState):
  boolean | undefined => state.auth.notificationsAccepted;

export default handleActions<UserState, AbstractPayload>({
  [loginSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (payload) {
        draft.loginData = payload;
        draft.isLogged = true;
      }
    },
  ),
  [logout.toString()]: produce(
    (draft: UserState, { payload }) => {
      draft.loginData = null;
      draft.temporaryAddress = null;
      draft.isLogged = false;
      if (payload?.error) {
        draft.genericError = payload.error;
      }
    },
  ),
  [setNotificationsSituation.toString()]: produce(
    (draft: UserState, { payload }) => {
      draft.notificationsAccepted = payload;
    },
  ),
  [recoverPasswordSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (payload) {
        draft.temporarySuccess = payload;
      }
    },
  ),
  [getAddressByCepSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      draft.temporaryAddress = payload;
    },
  ),
  [getAddressByLocationSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      draft.temporaryAddress = payload;
    },
  ),
  [refreshTokenSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (draft.loginData) {
        draft.loginData.tokens.access = payload.access;
        draft.loginData.tokens.refresh = payload.refresh;
      }
    },
  ),
  [updateUserSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (draft.loginData) {
        draft.loginData.profile = { ...draft.loginData.profile, ...payload };
      }
    },
  ),
  [createAddressSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (draft.loginData && payload) {
        draft.loginData.profile.client.client_address = [
          ...draft.loginData.profile.client.client_address.map(
            (address) => ({ ...address, default: false }),
          ),
          { ...payload, default: true },
        ];
      }
    },
  ),
  [updateAddressSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (draft.loginData) {
        draft.loginData.profile.client.client_address = [
          ...draft.loginData.profile.client.client_address.map(
            (address) => ({ ...address, default: payload.id === address.id }),
          ),
        ];
      }
    },
  ),
  [deleteAddressSuccess.toString()]: produce(
    (draft: UserState, { payload }) => {
      if (draft.loginData) {
        draft.loginData.profile.client.client_address = payload;
      }
    },
  ),
  [registerFailure.toString()]: produce(
    (draft: UserState, { payload }) => {
      draft.registerErrors = payload;
    },
  ),
  [registerClean.toString()]: produce(
    (draft: UserState) => {
      draft.registerErrors = null;
    },
  ),
}, INITIAL_STATE);
