import React from 'react';

import Modal from 'ui/components/Modal';
import { PageHeader, Title } from 'ui/components/Page';

import { ModalContent, Button, OutlinedButton } from './ModalDiscardCart.styled';

export default function ModalDiscardCart(props: {
  onAccept: () => void;
  onReject: () => void;
  disableReject?: boolean;
}): React.ReactElement {
  const { onAccept, onReject, disableReject } = props;

  const handleRejectPush = (): void => {
    onReject();
  };
  const handleAcceptPush = (): void => {
    onAccept();
  };

  return (
    <Modal close={handleRejectPush} modifiers={['ShowModal', 'ModalSmall']}>
      <ModalContent>
        <PageHeader>
          <Title>
            Esvaziar carrinho
          </Title>
          <p>
            Não é possível utilizar o mesmo carrinho para trocas e compras.
            <br />
            Ao entrar os itens que foram adicionados no carrinho serão removidos.
            <br />
            <br />
            {!disableReject ? ' Deseja remover os itens adicionados no carrinho?' : null}

          </p>
        </PageHeader>
        {!disableReject ? <OutlinedButton onClick={handleRejectPush}>Não, manter os itens</OutlinedButton> : null}
        <Button onClick={handleAcceptPush}>Sim, remover itens</Button>
      </ModalContent>
    </Modal>
  );
}
