import _ from 'lodash';
import { Action, ActionMeta } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest, put, select, call, debounce, take, all, delay, takeEvery,
} from 'redux-saga/effects';

import {
  createCart, updateCart, buyCart, getCartAlerts, getSavedCart, retrieveUpdatedCart,
} from 'services/api/cart';
import {
  getPaymentMethods,
  getShippingMethods,
  getSelfShippingMethods,
} from 'services/api/stores';

import {
  loadCartRequest,
  loadCartSuccess,
  loadCartFailure,
  addProductToCartRequest,
  addProductToCartSuccess,
  removeProductToCartRequest,
  removeProductToCartSuccess,
  syncCartRequest,
  syncCartSuccess,
  syncCartFailure,
  downloadCartRequest,
  downloadCartSuccess,
  downloadCartFailure,
  getCartState,
  getCart,
  clearCart,
  verifyCartRequest,
  verifyCartFailure,
  loadPaymentMethodsRequest,
  loadPaymentMethodsFailure,
  loadPaymentMethodsSuccess,
  loadShippingMethodsRequest,
  loadShippingMethodsFailure,
  loadShippingMethodsSuccess,
  loadSelfShippingMethodsRequest,
  loadSelfShippingMethodsFailure,
  loadSelfShippingMethodsSuccess,
  buyCartRequest,
  buyCartFailure,
  buyCartSuccess,
  listOrdersRequest,
  verifyCartSuccess,
  responseCreditCard,
  responseMessage,
  isLoading,
  productIsAddedInCart, getSelectedStore, getStoreBySlug,
} from 'store/ducks';
import {
  debouncedSyncCart,
  getCartNotificationsRequest,
  getCartNotificationsFailure,
  getCartNotificationsSuccess, setUnverifiedCart,
} from 'store/ducks/cart';
import { FetchMeta } from 'store/ducks/checkFetchReducerCreator';

import {
  Cart, CartErrorMessage, CartItem, ItemDisagreement, MismatchError, Product, Stock,
} from 'typing/models';

import { SYNC_CART } from 'utils/fetchs';

import { getBrandFromNumber } from '../../utils/card';
import { PAYMENT_TYPE_ONLINE_CREDIT_CARD } from '../../utils/contants';

function* loadCartAsync(): SagaIterator {
  try {
    const cart = localStorage.getItem('@bodega-mix/cart');
    if (cart) {
      const cartData = JSON.parse(cart);
      cartData.data.items = cartData.data.items.filter((item) => item.product.product_child !== null);
      yield put(loadCartSuccess(cartData));
      yield put(getCartNotificationsRequest());
    }
  } catch (e) {
    yield put(loadCartFailure(e));
  }
}

function* updateCartAsync(): SagaIterator {
  const cart = yield select(getCartState);
  localStorage.setItem('@bodega-mix/cart', JSON.stringify(cart));
}

function* syncCartAsync(action?: ActionMeta<string | number, FetchMeta>): SagaIterator {
  const cart: Cart = yield select(getCart);
  const onlyDownload = action?.payload === 'only_download';
  try {
    let response;
    if (onlyDownload) {
      response = yield call(retrieveUpdatedCart, cart.store?.slug ?? 'colmeia', cart);
    } else if (cart.id) {
      response = yield call(updateCart, cart.store?.slug, cart);
    } else {
      response = yield call(createCart, cart.store?.slug, cart);
    }

    if (response.cart_items.length !== 0) {
      response.items = response.items.map(
        (item: any):
          any => ({
          product: item.product,
          quantity: parseFloat(item.quantity),
          location_code: item.location_code,
        }),
      );
      response.items = response.items.filter((item: any): any => !!item.product.product_child);
    }
    yield put(syncCartSuccess(response, action?.meta.id));
    yield call(updateCartAsync);
    yield put(getCartNotificationsRequest());
  } catch (e) {
    const { cart_items = [] } = e.response?.data || {};
    const invalidChanges = cart_items.filter(
      (item: { quantity: CartErrorMessage }) => item.quantity,
    );

    yield all(invalidChanges.map((change: { quantity: CartErrorMessage }) => {
      const cartItem = cart.items.find(
        (item) => item.product.product_child.id === change.quantity.product_code,
      );

      if (cartItem && parseFloat(change.quantity.actual_stock) > 0) {
        return put(
          addProductToCartRequest(
            { ...cartItem, quantity: parseFloat(change.quantity.actual_stock) },
          ),
        );
      }

      if (cartItem && parseFloat(change.quantity.actual_stock) === 0) {
        return put(removeProductToCartRequest(cartItem.product));
      }

      return null;
    }));
    yield put(syncCartFailure(e, action?.meta.id));
  }
}

function* downloadCartAsync(): SagaIterator {
  // const cart: Cart = yield select(getCart);
  // try {
  //   let response;
  //
  //   if (cart.id) {
  //     response = yield call(getSavedCart, cart.store?.slug, cart.id);
  //
  //     const cartStore = yield select(getStoreBySlug(response.store));
  //
  //     const { cart_items, ...cartInfo } = response;
  //
  //     delete cartInfo.response_credit_cards;
  //     const cardData = {
  //       data: {
  //         ...cartInfo,
  //         store: cartStore,
  //         items: cart_items.map(
  //           (item: any):
  //             any => ({
  //             product: item.product_child.parent || item,
  //             quantity: parseFloat(item.quantity),
  //             location_code: item.location_code,
  //           }),
  //         ),
  //       },
  //     };
  //
  //     localStorage.setItem('@bodega-mix/cart', JSON.stringify(cardData));
  //     yield put(loadCartSuccess(cardData));
  //     yield put(downloadCartSuccess(cardData));
  //   }
  // } catch (e) {
  //   // yield put(downloadCartFailure(e, action?.meta.id));
  // }
}

function* removeProductToCartAsync({ payload }: Action<Product>): SagaIterator {
  yield put(removeProductToCartSuccess(payload));
  yield put(syncCartRequest());
}

function* addProductToCartAsync({ payload }: Action<CartItem>): SagaIterator {
  const cart: Cart = yield select(getCart);
  try {
    const response = yield call(retrieveUpdatedCart, cart.store?.slug, cart);
    console.log('addProductToCartAsync [1]', response);
    if (response.cart_items.length !== 0) {
      response.items = response.items.map(
        (item: any):
        any => ({
          product: item.product,
          quantity: parseFloat(item.quantity),
          location_code: item.location_code,
        }),
      );
    }
    console.log('addProductToCartAsync [2]', response);
    yield put(syncCartSuccess(response, undefined));
    console.log('addProductToCartAsync [3]');
    yield call(updateCartAsync);
    console.log('addProductToCartAsync [4]');
  } catch (e) {
    console.log('Error: ', e);
  }

  const hasProductInCart = yield select(productIsAddedInCart(payload.product.product_child.id));
  yield put(addProductToCartSuccess(payload));

  const productId = payload.product.id;

  if (hasProductInCart) {
    yield call(updateCartAsync);
    yield put(debouncedSyncCart(productId));
  } else {
    yield put(syncCartRequest(productId, productId));
  }
}

function* verifyCartAsync(): SagaIterator {
  try {
    const localCart: Cart = yield select(getCart);

    let disagreements: ItemDisagreement[] = [];

    if (localCart.id) {
      const { cart_items } = yield call(updateCart, localCart.store?.slug, localCart);

      disagreements = localCart.items.map((item): ItemDisagreement => {
        const serverItem = cart_items.find(
          (cartItem: any) => cartItem.product_id === item.product.product_child.id.toString(),
        );

        const itemDisagreement: ItemDisagreement = {
          types: [],
          item,
        };

        if (!parseFloat(serverItem.quantity)) {
          itemDisagreement.types.push('EMPTY_STOCK');
        } else {
          const maxQuantity = serverItem.product_child.stock.reduce(
            (total: number, stock: Stock) => total + stock.quantity, 0,
          );

          if (maxQuantity < item.quantity) {
            itemDisagreement.types.push('QUANTITY_NOT_AVAILABLE');
            itemDisagreement.maxQuantity = maxQuantity;
          }

          const suggestServerPrice = parseFloat(serverItem.suggest_price).toFixed(2);
          const currentPrice = (item.product.product_child.promo_price
              ?? item.product.product_child.price).toFixed(2);

          if (suggestServerPrice !== currentPrice) {
            itemDisagreement.types.push('UPDATED_VALUE');
            itemDisagreement.newPrice = parseFloat(serverItem.suggest_price);
          }
        }

        return itemDisagreement;
      });
    }

    yield put(verifyCartSuccess(disagreements.filter((disagreement) => disagreement.types.length)));
  } catch (e) {
    yield put(verifyCartFailure(e));
  }
}

function* buyCartAsync(params): SagaIterator {
  const syncCartLoading = yield select(isLoading(SYNC_CART));

  if (syncCartLoading) {
    yield take(syncCartSuccess.toString());
  }

  const cart: Cart = yield select(getCart);
  const hasCreditCardOnline = cart.payment_type?.payment_type === PAYMENT_TYPE_ONLINE_CREDIT_CARD;

  const captchaToken = params.payload?.captchaToken;

  try {
    yield call(updateCart, cart.store?.slug, cart);

    let creditCards = {};
    if (hasCreditCardOnline) {
      creditCards = {
        credit_cards: params.payload?.cards.map((card) => {
          const month = parseInt(card.expiration_date.split('/')[0]);
          const year = parseInt(card.expiration_date.split('/')[1]);

          return ({
            number: card.card_number,
            cvv: card.cvv,
            type: getBrandFromNumber(card.card_number),
            expire_month: month.toString().padStart(2, '0'),
            expire_year: year < 100 ? (year + 2000) : year,
            name_on_card: card.name,
            instalments: card.installment,
            amount: card.amount,
          });
        }),
      };
    }

    const response = yield call(buyCart, cart.store?.slug, cart.id || 'null', creditCards, captchaToken);
    localStorage.removeItem('@bodega-mix/cart');
    yield put(buyCartSuccess(response));
    yield put(listOrdersRequest());
  } catch (e) {
    if (hasCreditCardOnline) {
      const { payment, credit_cards } = e.response?.data;
      yield put(responseCreditCard({
        payment, credit_cards,
      }));
      yield put(setUnverifiedCart(cart.id));
    }

    const message = _.get(e, 'response.data.message', null);
    if (message) {
      const code = _.get(e, 'response.data.code', null);
      yield put(responseMessage({
        message, code,
      }));
      yield put(setUnverifiedCart(cart.id));
    }

    const errors = e.response?.data.errors;

    if (errors?.length) {
      const disagreements: (ItemDisagreement | null)[] = errors.map(
        (error: MismatchError): ItemDisagreement | null => {
          const item: CartItem | undefined = cart.items.find(
            (cartItem) => {
              const productId = cartItem.product.product_child.id.toString();
              return productId === error.quantity.product_code;
            },
          );

          if (item) {
            const disagreement: ItemDisagreement = {
              item,
              types: [],
            };

            if (error.quantity.message === 'missmatch_price') {
              disagreement.types.push('UPDATED_VALUE');
              disagreement.newPrice = parseFloat(error.quantity.actual_price);
            } else if (error.quantity.message === 'missmatch_quantity') {
              disagreement.types.push('QUANTITY_NOT_AVAILABLE');
            }

            return disagreement;
          }
          return null;
        },
      );

      yield put(verifyCartSuccess(disagreements.filter((disagreement) => !!disagreement)));
      yield put(setUnverifiedCart(cart.id));
    } else {
      yield put(buyCartFailure(e));
    }
  }
}

function* debouncedSyncCartAsync(action?: Action<string | number>): SagaIterator {
  yield put(syncCartRequest(action?.payload, action?.payload));
}

function* loadPaymentMethodsRequestAsync(): SagaIterator {
  try {
    const cart: Cart = yield select(getCart);

    if (cart) {
      const response = yield call(getPaymentMethods, cart.store?.slug || 'null', cart.id);
      yield put(loadPaymentMethodsSuccess(response));
    }
  } catch (e) {
    yield put(loadPaymentMethodsFailure(e));
  }
}

function* loadShippingMethodsRequestAsync(): SagaIterator {
  try {
    const cart: Cart = yield select(getCart);

    if (cart && cart.id) {
      const response = yield call(getShippingMethods, cart.store?.slug, cart.id);
      yield put(loadShippingMethodsSuccess(response));
    }
  } catch (e) {
    yield put(loadShippingMethodsFailure(e));
  }
}

function* loadSelfShippingMethodsRequestAsync(): SagaIterator {
  try {
    const cart: Cart = yield select(getCart);
    const store = yield select(getSelectedStore);

    if (cart && cart.id) {
      let response = yield call(getSelfShippingMethods, cart.store?.slug, cart.id);

      // TODO: Habilitar quando for permitido pelo cliente
      // if (store?.config?.use_template_delivery_to_my_address) {
      //   response.push({
      //     amount: 0,
      //     delivery_time: '',
      //     description: 'Entregar no meu endereço',
      //     identifier: 'delivery-my-address',
      //     kind: 'delivery_my_address',
      //   });
      // }

      response = response.map((item) => {
        if (item.kind === 'composite') {
          item.options.forEach((option, index) => {
            if (option.items.length > 1) {
              item.options[index] = {
                ...item.options[index],
                items: [
                  {
                    identifier: '',
                    description: item.options[index].identifier === 'corretor' ? 'Digite o nome do seu corretor' : 'Digite uma opção',
                  },
                  ...item.options[index].items,
                ],
              };
            }
          });
        }

        return { ...item };
      });
      yield put(loadSelfShippingMethodsSuccess(response));
    }
  } catch (e) {
    yield put(loadSelfShippingMethodsFailure(e));
  }
}

function* getNotificationsAsync(): SagaIterator {
  while (true) {
    const cart: Cart = yield select(getCart);

    if (cart && cart.id && cart.state !== 'completed') {
      try {
        const alerts = yield call(getCartAlerts, cart.store?.slug, cart.id);
        yield put(getCartNotificationsSuccess(alerts));
      } catch (e) {
        yield put(getCartNotificationsFailure(e));
      }
      yield delay(30 * 1000);
    } else {
      break;
    }
  }
}

export default function* watchCartActions(): SagaIterator {
  try {
    yield takeLatest(loadPaymentMethodsRequest.toString(), loadPaymentMethodsRequestAsync);
    yield takeLatest(loadShippingMethodsRequest.toString(), loadShippingMethodsRequestAsync);
    yield takeLatest(loadSelfShippingMethodsRequest.toString(),
      loadSelfShippingMethodsRequestAsync);
    yield takeLatest(buyCartRequest.toString(), buyCartAsync);
    yield takeLatest(loadCartRequest.toString(), loadCartAsync);
    yield takeLatest(addProductToCartRequest.toString(), addProductToCartAsync);
    yield takeLatest(verifyCartRequest.toString(), verifyCartAsync);
    yield takeLatest(removeProductToCartRequest.toString(), removeProductToCartAsync);
    yield takeLatest(clearCart.toString(), updateCartAsync);
    yield takeLatest(syncCartRequest.toString(), syncCartAsync);
    yield takeLatest(downloadCartRequest().toString(), downloadCartAsync);
    yield takeLatest(getCartNotificationsRequest.toString(), getNotificationsAsync);
    yield debounce(1000, debouncedSyncCart.toString(), debouncedSyncCartAsync);
  } catch (e) {
    console.warn(e);
  }
}
