import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Form from 'react-formal';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLoginData, isSuccess, updateUserClean, updateUserRequest,
} from 'store/ducks';
import { hasActiveCampaign } from 'store/ducks/user';

import { LoginData } from 'typing/store';

import ExchangeBalance from 'ui/components/ExchangeBalance';
import FeedbackMessage from 'ui/components/FeedbackMessage';
import {
  Page, Container, PageHeader, Title, PageBody,
} from 'ui/components/Page';

import { UPDATE_USER } from 'utils/fetchs';
import { phoneNumberMask, cpfMask, cnpjMask } from 'utils/masks';
import { EditProfileSchemaType, editProfileSchema } from 'utils/schemas';

import {
  FormGroup, Label, FormFooter, Separator,
} from './EditProfile.styled';

export default function EditProfile(): React.ReactElement {
  const loginData: LoginData | null = useSelector(getLoginData);

  const dispatch = useDispatch();
  const success = useSelector(isSuccess(UPDATE_USER));

  const identifierCode = loginData?.profile?.client.identifier_code || '';

  const profile = {
    first_name: loginData?.profile?.first_name || '',
    last_name: loginData?.profile?.last_name || '',
    email: loginData?.profile?.email,
    people_type: identifierCode.length === 11 ? 'pf' : 'pj',
    identifier_code: identifierCode.length === 11 ? cpfMask(identifierCode) : cnpjMask(identifierCode),
    phone_number: phoneNumberMask(loginData?.profile?.client.phone_number || ''),
  };

  const [editData, setEditData] = useState<EditProfileSchemaType>(profile);

  const editProfile = useCallback((): void => {
    dispatch(updateUserRequest(editData));
  }, [dispatch, editData]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(updateUserClean());
      }, 2000);
    }
  }, [success]);

  const hasCampaign = useSelector(hasActiveCampaign);

  return (
    <Page>
      <Container modifiers="MiddleSmall">
        {hasCampaign ? (
          <>
            <ExchangeBalance />
            <Separator />
          </>
        ) : null}
        <PageHeader modifiers="Center">
          <Title>Editar dados</Title>
        </PageHeader>
        <PageBody style={{
          paddingInline: 32,
        }}
        >
          <Form
            autoComplete="off"
            schema={editProfileSchema}
            value={editData}
            defaultValue={editProfileSchema.default()}
            onChange={(model: any): void => {
              model.phone_number = phoneNumberMask(model.phone_number);

              setEditData(model);
            }}
            onSubmit={editProfile}
          >
            <FormGroup>
              <Label>Nome</Label>
              <Form.Field name="first_name" className="form-control" />
              <Form.Message for="first_name" />
            </FormGroup>
            <FormGroup>
              <Label>Sobrenome</Label>
              <Form.Field name="last_name" className="form-control" />
              <Form.Message for="last_name" />
            </FormGroup>
            <FormGroup>
              <Label>E-mail</Label>
              <Form.Field name="email" className="form-control" type="email" disabled />
              <Form.Message for="email" />
            </FormGroup>
            <FormGroup>
              <Label>{profile.people_type === 'pf' ? 'CPF' : 'CNPJ'}</Label>
              <Form.Field name="identifier_code" className="form-control" disabled />
              <Form.Message for="identifier_code" />
            </FormGroup>
            <FormGroup>
              <Label>Telefone</Label>
              <Form.Field name="phone_number" className="form-control" type="telephone" />
              <Form.Message for="phone_number" />
            </FormGroup>
            <FormFooter>
              <FeedbackMessage modifiers={success ? ['SnackBar', 'Active', 'Success'] : []}>
                Edições salvas
              </FeedbackMessage>
              <FormGroup>
                <Form.Submit
                  type="submit"
                  className={classNames({
                    'btn btn-fill-out btn-block': true,
                    disabled: !editProfileSchema.isValidSync(editData),
                  })}
                >
                  Salvar
                </Form.Submit>
              </FormGroup>
            </FormFooter>
          </Form>
        </PageBody>
      </Container>
    </Page>
  );
}
